.screenContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: var(--color-gray-light-1);
}

.mainDivContainer {
    height: 540px;
    max-height: max-content;
    width: 900px;
    max-width: 96vw;
    background-color: var(--color-white);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    box-shadow: 0 0.3rem 0.6rem rgba(var(--color-black-rgb), 0.5);
    display: flex;
    flex-direction: row;
}

.divLeft,
.divRight {
    width: 50%;
    height: auto;
    border-radius: 10px 0 0 10px;
}

.divLeft {
    padding: 3.1rem;
    background-image: linear-gradient(to top right, #6e6e6e, #888);
}

.logoImg {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    padding: 0 2rem;
}

.logoDiv {
    display: flex;
    align-items: center;
}

.logo {
    width: 4rem;
    height: 4rem;
    margin-right: 1rem;
}

.logoHeading {
    font-size: 3.4rem;
    font-weight: 500;
    color: var(--color-white);
}

.descriptionDiv {
    margin-top: 16.2rem;
}

.descriptionHeading {
    font-size: 4rem;
    font-weight: 500;
    color: var(--color-white);
    line-height: 5rem;
}

.descriptionText {
    margin-top: 5vh;
    font-size: 15px;
    color: var(--color-white);
    font-weight: 400;
}

.divRight {
    /*background-color: none;*/
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 1%;
}

.srijconTitle {
    margin-top: 0;
}

.logoImg {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    padding: 2rem;
}

.leftDescription {
    display: flex;
    text-align: justify;
    justify-content: center;
    align-items: center;
    font-size: 1.4vw;
    margin: 0 auto;
    color: var(--color-gray-dark-1);
    font-style: italic;
}

.signUpAsk {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0 auto 2rem;
}

.signUpParagraph {
    margin-left: 1rem;
    color: var(--color-primary);
    cursor: pointer;
}

.textCheckOne,
.textCheckTwo {
    width: 100%;
    margin-top: 1rem;
    transition: all 0.2s;
}

.textCheckOne {
    margin-right: 0.5rem;
    transition: all 0.2s;
}

.textCheckTwo {
    margin-left: 0.5rem;
    transition: all 0.2s;
}

.textCheckThree {
    width: 100%;
    margin-top: 2rem;
}

/* Right Container  */
.editImgAvt1 {
    margin-top: -1rem;
}

.cameraIcon2 {
    position: relative;
    color: var(--color-white);
    background-color: var(--color-gray-medium-3);
    box-shadow: 0 0.3rem 0.6rem rgba(var(--color-black-rgb), 0.2);
    border-radius: 50%;
    padding: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 4rem;
    width: 4rem;
    left: 13.8rem;
    bottom: 6rem;
}

.cameraIcon2:hover {
    background-color: var(--color-gray-dark-2);
}

.cameraIcon2:active {
    transition: 0.55ms;
    transform: scale(0.96);
}

.fillCameraIcon {
    height: 90%;
    width: 90%;
}

.editImgAvt1 img {
    height: 18rem;
    width: 18rem;
    padding: 0.8rem;
    border-radius: 100%;
    background: var(--color-white);
    object-fit: cover;
    box-shadow: 0 0.3rem 0.6rem rgba(var(--color-black-rgb), 0.2);
}


.rightContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
}

.rightContainerDesc {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: max-content;
}

.rightContainerDesc > p {
    text-align: center;
    font-size: 14px;
    opacity: 0.8;
    width: 60%;
}

.rightAvtContainer {
    height: 18rem;
    width: 18rem;
    margin: 0.1rem auto;
}

.rightAvt {
    width: 18rem;
    height: 18rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    box-shadow: 0 0.3rem 0.6rem rgba(var(--color-black-rgb), 0.2);
}

.rightAvt img {
    object-fit: cover;
    height: 18rem;
    width: 18rem;
    border-radius: 100%;
    padding: 4%;
}

.cameraIconAsAvt {
    object-fit: cover;
    height: 18rem;
    width: 18rem;
    border-radius: 100%;
    /* padding: 30%; */
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-gray-medium-3);
    background-color: var(--color-gray-light-2);
    cursor: pointer;
    transition: all 0.1s;
}

.cameraIcon {
    height: 5.5rem;
    width: 5.5rem;
}

.cameraIconAsAvt:hover .cameraIcon {
    transform: scale(1.1);
    transform-origin: center center;
}

.cameraIconAvt {
    position: relative;
    color: var(--color-white);
    background-color: var(--color-gray-medium-3);
    box-shadow: 0 0.3rem 0.6rem rgba(var(--color-black-rgb), 0.2);
    border-radius: 50%;
    height: 4.5rem;
    width: 4.5rem;
    padding: 0.5rem;
    left: 13.5rem;
    bottom: 6rem;
    cursor: pointer;
    transition: all 0.2s;
}

.cameraIconAvt:hover {
    background-color: var(--color-gray-dark-2);
}

.cameraIconAvt:active {
    transition: 0.55ms;
    transform: scale(0.96);
}

.formContainer {
    display: flex;
    flex-direction: column;
    gap: 1em;
}

/* SignIn Forms Fields Container  */
.formFieldsContainer {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    max-height: max-content;
}

.formFields {
    gap: 4rem;
}

.fieldsOnly {
    margin: 1rem 2rem;
}

.formFieldsContainer > span {
    align-self: center;
    margin-top: 2em;
    color: var(--color-gray-medium-3);
}

.formFieldsContainer > span > a {
    text-decoration: none;
    color: var(--color-primary);
}

.textFieldContainer {
    font-size: 12px;
}

/* SignIn heading */
.heading {
    font-size: 3.5rem;
    color: var(--color-gray-dark-1);
    font-weight: 700;
}

.orText {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.5rem auto;
}

/* SignIn Buttons */
.buttonDisabled {
    opacity: 0.5;
    cursor: default !important;
}

.buttonDisabled,
.button {
    text-decoration: none;
    margin: 2rem auto 0;
    padding: 1.15rem 2rem;
    font-size: 1.4rem;
    background-color: var(--color-primary);
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-white);
    border-radius: 0.5rem;
    cursor: pointer;
    box-shadow: 0 0.3rem 0.6rem rgba(var(--color-black-rgb), 0.2);
    justify-self: center;
    align-self: center;
    outline: none;
    border: none;
    font-family: 'Roboto', 'sans-serif';
}

.button:hover {
    background-color: var(--color-primary-light);
}

.button:active {
    transition: 0.55ms;
    transform: scale(0.96);
}

.signInFormErrorMsg {
    min-height: 2rem;
    display: flex;
    padding: 0.25em 0.25em;
    width: 100%;
}

/* Forgot Password and Remember Me container */
.moreFeatures {
    display: flex;
    justify-content: space-between;
    max-height: max-content;
}

.moreFeatures > p {
    font-size: 14px;
    opacity: 0.8;
    height: max-content;
}

.moreFeatures > button {
    background-color: var(--color-white);
    outline: 0;
    border: 0;
    cursor: pointer;
    font-size: 14px;
    margin: 0;
}

.checkbox {
    padding: 0;
    display: flex;
    gap: 0.5em;
    align-items: center;
    margin-left: 5rem;
    height: max-content;
}

.checkbox input {
    width: 12px;
    height: 12px;
}

.checkbox label {
    font-size: 14px;
    color: var(--color-gray-medium-3);
    display: inline;
    padding: 0;
    margin: 0 auto;
}

.socialLoginContainer {
    display: flex;
    flex-direction: row;
    gap: 2.5em;
    justify-content: center;
    align-items: center;
    margin: 1rem auto;
}

/* Social Login Elements */
.socialLogin {
    display: inline-flex;
    align-items: center;
}

.socialLoginIcon {
    width: 36px;
}

/* Social Login and Form Divider */
.divider {
    text-transform: uppercase;
}

.divider::before {
    height: 2px;
    background-color: var(--color);
    width: 2rem;
}

.signUpLink {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.signUpLink p {
    font-size: 14px;
    opacity: 0.8;
}

.signUpLink span {
    font-size: 14px;
    opacity: 0.8;
}

.facebookBtn {
    background: transparent;
    border: none;
}

.forgotPasswordText {
    margin: 0 5rem 0 0;
    color: var(--color-gray-medium-3);
    height: max-content;
}

.gridMake {
    display: flex;
    flex-direction: row;
}

@media only screen and (max-width: 1200px) {
    .divLeft {
        padding: 3.8rem;
    }

    .descriptionDiv {
        margin-top: 18.7rem;
    }
}

@media (min-width: 475px) and (max-width: 768px) {
    .mainDivContainer {
        width: 425px;
        max-width: 96vw;
    }

    .divLeft {
        display: none;
    }

    .divRight {
        width: 100%;
    }
}

@media (min-width: 345px) and (max-width: 475px) {
    .screenContainer {
        background-color: var(--color-white);
    }

    .mainDivContainer {
        background-color: var(--color-white);
        width: 100vw;
        border-radius: 0;
        box-shadow: none;
    }

    .divLeft {
        display: none;
    }

    .divRight {
        width: 100%;
        padding: 0;
        margin: 0;
    }
}

@media screen and (max-width: 345px) {
    .screenContainer {
        background-color: var(--color-white);
    }

    .mainDivContainer {
        background-color: var(--color-white);
        width: 100vw;
        border-radius: 0;
        box-shadow: none;
    }

    .divLeft {
        display: none;
    }

    .divRight {
        width: 100%;
        padding: 0;
        margin: 0;
    }

    .fieldsOnly {
        margin: 1rem;
    }

    .checkbox {
        margin-left: 1rem;
    }

    .forgotPasswordText {
        margin-right: 1rem;
    }
}

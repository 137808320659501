/* Right side of Navbar */
.right {
  display: flex;
  align-items: center;
}

.iconContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /*margin-left: 3rem;*/
  margin-left: 2.4rem;
}

/* Add Items */
.add {
  background-color: var(--color-primary);
  cursor: pointer;
  padding: 0.5rem;
  height: 3rem;
  width: 3rem;
  /*margin: 0.5rem;*/
  color: var(--color-white);
  border-radius: 50%;
  transition: all 0.2s !important;


  margin-top: 0;
}

/* Add Items hover */
.add:hover {
  padding: 0.7rem;
}

/* Navbar items(notification and message icons) styles */
.item {
  /*margin-left: 1.5rem;*/
  /* margin-left: 2rem; */
  padding: 0.3rem;
  height: 3rem;
  width: 3rem;
  color: var(--color-gray-medium-2);
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.2s !important;


  margin-top: 0;
}

/* Navbar item h over state */
.item:hover {
  /* background-color: var(--color-primary);
  color: var(--color-white); */
  transform: scale(1.1);
  transform-origin: center center;
  color: var(--color-gray-dark-1);
}

/* Avtar */
.avatar {
  cursor: pointer;
  /*margin-left: 1.5rem;*/
  /* margin-left: 2.2rem; */
  /*margin: 0.5rem;*/
  height: 3rem;
  width: 3rem;
}

/* ? Settings icon below 900px */
.settings {
  padding: 0.5rem;
  border-radius: 0.5rem;
  height: 4rem;
  width: 4rem;
  color: var(--color-gray-dark-1);
  cursor: pointer;
  padding-left: 0.8rem;
}

.joinButton {
  text-decoration: none;
  padding: 1.15rem;
  font-size: 1.4rem;
  background-color: var(--color-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-white);
  border-radius: 0.5rem;
  cursor: pointer;
  box-shadow: 0 0.3rem 0.6rem rgba(var(--color-black-rgb), 0.2);
  outline: none;
  border: none;
  font-family: 'Roboto', 'sans-serif';
  text-transform: uppercase;
}

.joinButton:hover {
  background-color: var(--color-primary-light);
}

.joinButton:active {
  transition: 0.55ms;
  transform: scale(0.96);
}

/* @media (max-width: 900px) {
  .add {
    height: 4rem;
    width: 4rem;
    padding: 0.5rem;
  }
  .avatar {
    max-height: 4rem;
    max-width: 4rem;
  }
} */

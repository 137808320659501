.mainDiv img {
  width: 100%;
  /*aspect-ratio: 3/2;*/
  object-fit: cover;
  display: flex;
}

@media only screen and (max-width: 600px) {
  .mainDiv img {
    width: 100%;
    aspect-ratio: 4/3;
    object-fit: cover;
  }
}


.cropContainer {
    position: relative;
    width: 100%;
    height: 35vh;
    background-color: black;
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
    border-radius: 0.7rem;
}
.videoBox{
    object-fit: contain; 
    max-width: 100%;
    max-height: 100%;
    border-radius:0.7rem;
}

.sliderBox, .sliderBoxDisabled {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}
.sliderBoxDisabled {
    filter:opacity(0.5);
    cursor:not-allowed;
}

.sliders {
    width: 95%;
}

.typo {
    font-size: 1.6rem;
    margin-left: -0.9rem;
}
.editContainer{
    width: 100%;
}
.outerCropContainer{
    border-radius: 0.5rem;
    height: 35vh;
    width: 100%;
}

@media only screen and (max-width: 650px) {
    .outerCropContainer, .cropContainer {
        width: 100%;
        height: 55vw;
    }

    .individualSlider{
        padding: 1rem 0rem;
    }
    .sliderBox, .sliderBoxDisabled{
        margin-bottom: 1.5rem;
    }
}


.MuiSpeedDial-root {
  z-index: 0 !important;
}

.MuiSpeedDial-root button {
  height: 5.2rem !important;
  width: 5.2rem !important;
  background-color: var(--color-gray-dark-2) !important;
  /* background-color: var(--color-primary) !important; */
  transition: all 0.2s !important;
}

.MuiSpeedDial-root button:hover {
  background-color: var(--color-gray-dark-1) !important;
  /* background-color: var(--color-primary-light) !important; */
}

.MuiSpeedDial-actions button {
  background-color: var(--color-white) !important;
  height: 4rem !important;
  width: 4rem !important;
}

.MuiSpeedDial-actions button:hover {
  background-color: var(--color-white) !important;
}

.MuiTooltip-popper .MuiTooltip-tooltip {
  font-size: 1.4rem !important;
}

/* ? Backdrop styling */
.MuiBackdrop-root {
  background-color: rgba(var(--color-black-rgb), 0.7) !important;
  backdrop-filter: blur(1rem);
}

/* .MuiSpeedDialAction-staticTooltip {
} */
.MuiSpeedDialAction-staticTooltipLabel {
  letter-spacing: 0.1rem;
  color: var(--color-gray-medium-3);
  box-sizing: content-box;
  background-color: #fff;
  cursor: default;
  font-size: 1.4rem;
  font-weight: 500;
  left: -100%;
  line-height: 1.4em;
  opacity: 1;
  padding: 0.4rem 1rem;
  position: absolute;
  width: max-content;
  z-index: 1500;
}
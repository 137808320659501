.tvPage {
    /* min-height: 100vh; */
    padding: 2rem 0;
    /* padding: 2rem 8rem; */
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background-color: var(--color-gray-light-1);
}

.main {
    width: 46.5%;
    /*height: 100vh;*/
    margin: 0 2rem;
    max-width: 560px;
}

.navbar {
    position: sticky;
    min-width: 100%;
    top: 0;
    left: 0;
    padding: 0.7rem 2rem;
    background-color: var(--color-white);
    box-shadow: 0 0.3rem 1rem rgba(var(--color-black-rgb), 0.1);
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.logoContainer {
    display: flex;
    align-items: center;
    margin-right: 4rem;
    cursor: pointer;
    transition: all 0.2s;
}

/* Navbar Logo styles */
.logo {
    height: 4rem;
    margin-right: 0.5rem;
}

.logoText {
    font-size: 3rem;
    font-weight: 500;
    color: var(--color-gray-dark-1);
}

.logoContainer h1 {
    font-size: 4rem;
    font-weight: 500;
    line-height: 5rem;
}

.signInButton {
    text-decoration: none;
    padding: 1.15rem;
    font-size: 1.4rem;
    background-color: var(--color-primary);
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-white);
    border-radius: 0.5rem;
    cursor: pointer;
    box-shadow: 0 0.3rem 0.6rem rgba(var(--color-black-rgb), 0.2);
    outline: none;
    border: none;
    font-family: 'Roboto', 'sans-serif';
    text-transform: uppercase;
}

.signInButton:hover {
    background-color: var(--color-primary-light);
}

.signInButton:active {
    transition: 0.55ms;
    transform: scale(0.96);
}

@media only screen and (max-width: 1200px) {
    .main {
        margin-right: 0;
        min-width: 560px;
    }
}

@media only screen and (max-width: 900px) {
    .tvPage {
        padding: 2%;
    }

    .main {
        width: 560px;
        margin: 0;
        /* max-width: 60vw; */
    }
}

@media only screen and (max-width: 600px) {
    .tvPage {
        padding: 2%;
        background-color: #fff !important;
    }

    .main {
        width: 100vw;
        min-width: 100vw;
        max-width: 560px;
        margin: 0;
    }
}
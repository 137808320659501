/* Discuss Input Form */
.discussForm {
  position: relative;
  /* margin-top: 0.5rem; */
}

/* Discuss input style */
.discussInput {
  width: 100%;
  font-size: inherit;
  color: var(--color-gray-dark-2);
  font-weight: 400;
  font-family: inherit;
  padding: 1.1rem 4rem 1.3rem 1.5rem;
  border: none;
  border-top: 1px solid var(--color-gray-light-3);
  border-radius: 0 0 1.1rem 1.1rem;
}

.discussInput:focus {
  outline: none;
}

/* Discuss input placeholder style */
.discussInput::placeholder {
  color: var(--color-gray-medium-2);
  font-weight: 500;
}

/* Send Button */
.sendBtn {
  position: absolute;
  right: 1.5rem;
  top: 50%;
  transform: translateY(-50%) rotate(42deg);
  cursor: pointer;
  background: none;
  border: none;
}

/* Acknowledgement Container */
.acknowledgementContainer {
  position: absolute;
  right: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

/* Send Icon */
.sendIcon {
  color: var(--color-gray-dark-3);
  padding: 0.2rem;
  stroke-width: 1.5px;
}

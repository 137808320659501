.editPasswordModal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: fit-content;
    width: 45rem;
    /*min-height: 26.6rem;*/
    background-color: white;
    border-radius: 1rem;
    text-align: center;
    margin-bottom: 0;
    max-width: 95vw !important;
}

.header {
    /*position: relative;*/
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 5rem;
    padding: 0 1rem;

}

.header h4 {
    text-align: left;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.6rem;
}

.box {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem 0 0;
}

.formEditPassword {
    margin: auto 0 !important;
    width: 100% !important;
}

.buttonUpdate {
    display: flex;
    justify-content: center;
    align-items: center;
}

.updateButtonDisabled {
    opacity: 0.5;
    cursor: default !important;
}

.updateButtonEnabled,
.updateButtonDisabled {
    text-decoration: none;
    padding: 1.15rem 2.5rem;
    font-size: 1.4rem;
    background-color: var(--color-primary);
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-white);
    border-radius: 0.5rem;
    cursor: pointer;
    box-shadow: 0 0.3rem 0.6rem rgba(var(--color-black-rgb), 0.2);
    margin: 1.5rem;
    outline: none;
    border: none;
    font-family: 'Roboto', 'sans-serif';
}

.updateButtonEnabled:hover {
    background-color: var(--color-primary-light);
}

.updateButtonEnabled:active {
    transition: 0.55ms;
    transform: scale(0.96);
}


/*.modal {*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    overflow-y: auto;*/
/*    border: none !important;*/
/*    outline: none !important;*/
/*}*/

/*.wholeModal {*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    flex-direction: column;*/
/*    width: min(50rem, 100vw);*/
/*    background: white;*/
/*    border-radius: 1rem;*/
/*    box-shadow: 0 0.3rem 0.6rem rgba(var(--color-black-rgb), 0.2);*/
/*    outline: none !important;*/
/*}*/
/*.modalHeading {*/
/*    height: 5rem;*/
/*    display: flex;*/
/*    justify-content: space-between;*/
/*    align-items: center;*/
/*    width: 47rem;*/
/*}*/

/*.modalHeading p {*/
/*    color: #a3a3a3;*/
/*    font-size: 1.6rem;*/
/*    font-weight: 500;*/
/*    line-height: 2rem;*/
/*    margin-left: 1.5rem;*/
/*}*/


/*.innerModal{*/
/*    height: min(62rem, 87vh);*/
/*    width: min(50rem, 100vw);*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    align-items: center;*/
/*}*/

/*.insideEditModal, .loader{*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    height: min(58rem, 80vh);*/
/*    width: 50rem;*/
/*    padding: 0rem 2rem;*/
/*    overflow-y: auto;*/
/*    overflow-x: hidden;*/
/*}*/

/*.insideEditModal::-webkit-scrollbar {*/
/*    width: .7rem;*/
/*}*/
/*  !* Track *!*/
/*  .insideEditModal::-webkit-scrollbar-track {*/
/*    background: #f1f1f1;*/
/*}*/
/*  */
/*!* Handle *!*/
/*.insideEditModal::-webkit-scrollbar-thumb {*/
/*background: #888;*/
/*}*/

/*!* Handle on hover *!*/
/*.insideEditModal::-webkit-scrollbar-thumb:hover {*/
/*background: #555;*/
/*}*/

/*.dropdownOption {*/
/*    font-size: 1.4rem !important;*/
/*}*/

/*.nextButtonContainer{*/
/*    width:95%; */
/*    display:flex; */
/*    justify-content:center;*/
/*    margin-bottom: 2rem;*/
/*}*/

/*.nextButton{*/
/*    width: 95%;*/
/*}*/
/*.btnSaveDisabled {*/
/*    opacity: 0.5;*/
/*    cursor: default !important;*/
/*}*/

/*.btnSaveDisabled,*/
/*.btnSave {*/
/*    text-decoration: none;*/
/*    padding: 1.15rem 2.75rem;*/
/*    font-size: 1.4rem;*/
/*    background-color: var(--color-primary);*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    color: var(--color-white);*/
/*    border-radius: 0.5rem;*/
/*    cursor: pointer;*/
/*    box-shadow: 0 0.3rem 0.6rem rgba(var(--color-black-rgb), 0.2);*/
/*    outline: none;*/
/*    border: none;*/
/*    font-family: 'Roboto', 'sans-serif';*/
/*}*/

/*.btnSave:hover {*/
/*    background-color: var(--color-primary-light);*/
/*}*/

/*.btnSave:active {*/
/*    transition: 0.55ms;*/
/*    transform: scale(0.96);*/
/*}*/

/*@media only screen and (max-width: 650px) {*/
/*    .wholeModal {*/
/*        width: 100vw;*/
/*        height: 100vh;*/
/*        border-radius: 0px;*/
/*        margin-top: 0px;*/
/*        justify-content: space-evenly;*/
/*    }*/
/*    .modalHeading{*/
/*        width: 100vw;*/
/*        height: 5vh;*/
/*        padding: 2.5rem 2rem;*/
/*    }*/
/*    .innerModal{*/
/*        height: 90vh;*/
/*    }*/
/*    .insideEditModal , .loader{*/
/*        width: 100vw;*/
/*        height: 85vh;*/
/*        justify-content: flex-start;*/
/*    }*/
/*    .nextButtonContainer{*/
/*        margin-bottom: 0.5rem;*/
/*    }*/
/*}*/

/*.loader{*/
/*    justify-content: center;*/
/*}*/
/* TV Card dropdown container */
.dropdown {
    position: absolute;
    top: 3rem;
    right: 0.5rem;
    z-index: 1;
    box-shadow: 0 0.8rem 1.6rem rgba(0, 0, 0, 0.1);
    background-color: var(--color-white);
}

/* Dropdown element */
.element {
    display: flex;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem;
    height: 4.5rem;
    cursor: pointer;
    border-bottom: 1px solid var(--color-gray-light-1);
    border-radius: 0.5rem
}

.element:hover {
    background-color: var(--color-gray-light-1);
}

.element:not(:last-child) {
    border-bottom: 1px solid var(--color-gray-light-1);
}

.secondModal {
  position: absolute;
  top: 50%;
  left: 50%;
  background: #fff;
  transform: translate(-50%, -50%) !important;
  width: 50rem;
  /* height: 41rem; */
  height: max-content;
  border-radius: 1rem;
  box-shadow: 0 0.3rem 0.6rem rgba(var(--color-black-rgb), 0.2);
  max-width: 95vw !important;
}
.secondModalMain {
  margin: 3rem;
}

.secondModalBody {
  display: flex;
  align-items: center;
}
.secondModalTitle {
  color: var(--color-gray-medium-3);
}

.secondModalFormField {
  width: 100%;
  height: 100%;
}
.secondModalQuery {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin: 1rem 0;
  height: 18rem;
  border: 0.1rem solid #6e6e6e;
  border-radius: 0.5rem;
}
.secondModalPasswordInput {
  width: 100%;
  margin: 1rem 0;
  padding: 1rem;
  border: 0.1rem solid #6e6e6e;
  border-radius: 0.5rem;
}
.secondModalPasswordCheckSuccess {
  font-size: 1.3rem;
  color: green;
}
.secondModalPasswordCheckFail {
  font-size: 1.3rem;
  color: red;
}
.secondModalContent {
  height: 100%;
}
.secondModalQuery:focus {
  outline: 1px solid black;
}
.secondModalPasswordInput:focus {
  outline: 1px solid black;
}
.proceedBtnBox {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.proceedBtnEnabled,
.proceedBtnDisabled {
  text-decoration: none;
  padding: 1.15rem 2.5rem;
  font-size: 1.4rem;
  background-color: var(--color-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-white);
  border-radius: 0.5rem;
  cursor: pointer;
  box-shadow: 0 0.3rem 0.6rem rgba(var(--color-black-rgb), 0.2);
  margin: 0 0.5rem;
  margin-top: 2rem;
  outline: none;
  border: none;
  font-family: 'Roboto', 'sans-serif';
}

.proceedBtnEnabled:hover {
  background-color: var(--color-primary-light);
}
.proceedBtnEnabled:active {
  transition: 0.55ms;
  transform: scale(0.96);
}

.proceedBtnDisabled {
  opacity: 0.4;
  cursor: not-allowed;
}
textarea {
  padding: 1rem;
  resize: none;
}
@media only screen and (max-width: 900px) {
  .secondModalTitle {
    color: var(--color-gray-dark-2);
  }
}

/* Dropdown */
.dropdown {
    position: absolute;
    right: 0;
    top: 2.5rem;
    background-color: var(--color-white);
    box-shadow: 0 0.1rem 1rem rgba(var(--color-black-rgb), 0.2);
    border-radius: 0.4rem;
    z-index: 10;
}

/* Dropdown Element */
.element {
    display: flex;
    align-items: center;
    width: 10rem;
    padding: 0.7rem 1rem;
    cursor: pointer;
}

.element:hover {
    background-color: var(--color-gray-light-1);
}

.element:not(:last-child) {
    border-bottom: 1px solid var(--color-gray-light-1);
}

/* icon */
.icon,
.flaggedIcon {
    height: 1.6rem;
    width: 1.6rem;
    margin-right: 1rem;
    color: var(--color-gray-dark-2);
    stroke-width: 2px;
}

.flaggedIcon {
    color: var(--color-primary);
    fill: var(--color-primary);
}

.active,
.inactive {
  cursor: pointer;
  background-color: rgba(var(--color-white-rgb), 0.5);
  border: none;
  border-radius: 50%;
  height: 0.7rem;
  width: 0.7rem;
  margin: 0 0.3rem 1rem 0.3rem;
  box-shadow: 0 0.2rem 0.5rem rgba(var(--color-black-rgb), 0.4);
}

.active {
  background-color: var(--color-white);
}

.copyLinkModal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: max-content;
    background: var(--color-white);
    border-radius: 1rem;
    box-shadow: 0 0.3rem 0.6rem rgba(var(--color-black-rgb), 0.2);
    max-width: 95vw !important;
    padding: 1% 2%;
}

.copyLinkModalText {
    font-size: 1.6rem;
    text-align: center;
    color: var(--color-gray-medium-3);
}

@media only screen and (max-width: 900px) {
    .copyLinkModalText {
        color: var(--color-gray-dark-2);
    }
}

@media only screen and (max-width: 600px) {
    .copyLinkModal {
        padding: 4% 6%;
    }
}

/* Search form styles */
.form {
  position: relative;
}

/* Search icon styles */
.icon {
  position: absolute;
  top: 50%;
  left: 1rem;
  height: 2.2rem;
  width: 2.2rem;
  color: var(--color-gray-medium-1);
  transform: translateY(-50%);
  transition: all 0.2s;
}

/* Search Input Styles */
.input {
  color: var(--color-gray-dark-2);
  font-size: inherit;
  font-family: inherit;
  padding: 0.8rem 1rem 0.8rem 4rem;
  padding-left: 36px;
  border-radius: 2.5rem;
  border: none;
  width: 40rem;
  /* width: 400px; */
  height: 35px;
  line-height: 1;
  outline: 1px solid var(--color-gray-light-3);
  transition: all 0.2s;
}

/* Search input placeholder styles */
.input::placeholder {
  color: var(--color-gray-medium-1);
  transition: all 0.2s;
}

/* Input styles change on input focus */
.input:focus {
  outline: 1px solid var(--color-gray-dark-2);
  background-color: #fff;
}

/* Search icon styles change on input focus */
.input:focus + .icon {
  color: var(--color-gray-dark-2);
}

/* Input placeholder styles change on input focus */
.input:focus::placeholder {
  color: var(--color-gray-dark-2);
}

/* Close Button */
.close {
  position: absolute;
  right:5px;
  top: 50%;
  transform: translateY(-50%);
  height: 2.2rem;
  width: 2.2rem;
  color: var(--color-gray-dark-3);
  cursor: pointer;
}

.creatoricon {
  position: relative;
  margin-left: -40px;
  padding: 4px;
  /* background-color: #ccc; */
  /* color: #fff; */
  border-radius: 50px;
  width: 24px;
  height: 24px;
  display: none;
  /* margin-top: -0.4%; */
  margin-top: -2px;
}

@media (max-width: 900px) {
  .input {
    width: 35rem;
  }
}
@media (max-width: 768px) {
  .input {
    width: 22rem;
  }
} 
@media (max-width: 600px) {
  .input {
    width: 100%;
  }
} 
/* @media (max-width: 600px) {
  .input {
    font-size: 1.8rem;
    min-height: 4rem;
    margin: 0.5rem 0;
    width: 20rem;
    display: none;
  }

  .creatoricon {
    display: inline;
    background-color: #ccc;
    zoom: 130%;
    margin-left: -26px;
  }
} */

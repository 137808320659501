/* Description Container */
.descriptionContainer {
    padding: 0 2.5rem;
    margin-bottom: 1rem;
    /* width: 103.6%; */
}

/* Description */
.description {
    text-align: justify;
    /* color: #CCC; */
}

/* Icon */
.icon {
    color: var(--color-black);
    cursor: pointer;
}

/* Close description icon */
.closeDescriptionIcon {
    color: var(--color-gray-dark-1);
}

.tvCard {
    border-radius: 1rem;
    box-shadow: 0 0.8rem 1.6rem rgba(0, 0, 0, 0.1);
    background-color: var(--color-white);
    overflow: hidden;
    transition: all 0.2s ease;
}

@media only screen and (max-width: 600px) {
    .tvCard {
        border-radius: 0;
    }

    .lastDiv {
        height: 4.8rem;
    }
}
.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    border: none !important;
    outline: none !important;
}

.wholeModal {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: min(50rem, 100vw);
    background: var(--color-white);
    border-radius: 1rem;
    outline: none !important;
}

.modalHeading {
    height: 5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 47rem;
}

.innerModal {
    height: min(62rem, 87dvh);
    width: min(50rem, 100vw);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 2rem;
}

.insideEditModal, .loader {
    height: min(48rem, 70dvh);
    width: 50rem;
    display: flex;
    flex-direction: column;
    /*align-items: center;*/
    justify-content: flex-start;
    padding: 0 2rem;
    overflow-y: auto;
    overflow-x: hidden;
}

.UploadMediaContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0.1rem dashed var(--color-gray-medium-3);
    border-radius: 0.5rem;
    width: 100%;
    aspect-ratio: 3/0.8;
    margin: 1rem 0;
    /*padding: 0 2rem;*/
    padding: 2rem;
}

.UploadMediaContainer button {
    border: none;
    background: none;
}

.uploadIconDisabled {
    cursor: not-allowed !important;
}

.uploadIcon, .uploadIconDisabled {
    cursor: pointer;
    font-size: 6rem;
    color: var(--color-white);
    fill: var(--color-primary);
}

.uploadIcon:hover {
    fill: var(--color-primary-light);
}

.uploadIcon:active {
    transition: 0.55ms;
    transform: scale(0.96);
}

.descriptionText {
    cursor: pointer;
    line-height: 1.7rem;
    font-size: 1.4rem;
    font-weight: 500;
    color: var(--color-gray-medium-3);
}

.insideEditModal::-webkit-scrollbar {
    width: 0.7rem;
}

/* Track */
.insideEditModal::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.insideEditModal::-webkit-scrollbar-thumb {
    background: var(--color-gray-dark-3);
}

/* Handle on hover */
.insideEditModal::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.saveButtonContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
}

.btnSaveDisabled {
    opacity: 0.5;
    cursor: default !important;
}

.btnSaveDisabled,
.btnSave {
    width: 100%;
    text-decoration: none;
    padding: 1.15rem 2.75rem;
    font-size: 1.4rem;
    background-color: var(--color-primary);
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-white);
    border-radius: 0.5rem;
    cursor: pointer;
    box-shadow: 0 0.3rem 0.6rem rgba(var(--color-black-rgb), 0.2);
    outline: none;
    border: none;
    font-family: 'Roboto', 'sans-serif';
}

.btnSave:hover {
    background-color: var(--color-primary-light);
}

.btnSave:active {
    transition: 0.55ms;
    transform: scale(0.96);
}

@media only screen and (max-width: 650px) {
    .wholeModal {
        width: 100vw;
        height: 100dvh;
        border-radius: 0;
        margin-top: 0;
    }

    .modalHeading {
        width: 100vw;
        padding: 2.5rem 2rem;
        height: 5dvh;
    }

    .UploadMediaContainer {
        width: 90vw;
        height: 25dvh;
    }

    .insideEditModal, .loader {
        width: 100vw;
        height: 65dvh;
        margin-bottom: 1.5dvh;
        justify-content: flex-start;
    }

    .innerModal {
        height: 90dvh;
    }
    .saveButtonContainer {
        margin-bottom: 0.5rem;
    }

}
.main {
  width: 100%;
  margin: 0 10rem !important;
}
.qJTHM {
  -webkit-user-select: none;
  color: #202124;
  direction: ltr;
  -webkit-touch-callout: none;
  font-family: 'Roboto-Regular', arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  margin: 0;
  overflow: hidden;
  -webkit-text-size-adjust: 100%;
}
.ynRLnc {
  left: -9999px;
  position: absolute;
  top: -9999px;
}
.L6cTce {
  display: none;
}
.bltWBb {
  word-break: break-all;
}
.hSRGPd {
  color: #1a73e8;
  cursor: pointer;
  font-weight: 500;
  text-decoration: none;
}
.Bz112c-W3lGp {
  height: 16px;
  width: 16px;
}
.Bz112c-E3DyYd {
  height: 20px;
  width: 20px;
}
.Bz112c-r9oPif {
  height: 24px;
  width: 24px;
}
.Bz112c-uaxL4e {
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
.svg {
  display: block;
}
.main,
.main * {
  border: none;
  margin: 0;
  padding: 0;
}
.fFW7wc-ibnC6b > .aZ2wEe > div {
  border-color: #4285f4;
}
.P1ekSe-ZMv3u > div:nth-child(1) {
  background-color: #1a73e8 !important;
}
.P1ekSe-ZMv3u > div:nth-child(2),
.P1ekSe-ZMv3u > div:nth-child(3) {
  background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.7),
      rgba(255, 255, 255, 0.7)
    ),
    linear-gradient(to right, #1a73e8, #1a73e8) !important;
}
.haAclf {
  display: inline-block;
}
.button {
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: background-color 0.218s, border-color 0.218s;
  transition: background-color 0.218s, border-color 0.218s;
  -webkit-user-select: none;
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border: 1px solid #dadce0;
  color: #3c4043;
  cursor: pointer;
  font-family: 'Google Sans', arial, sans-serif;
  font-size: 14px;
  /* height: 40px; */
  letter-spacing: 0.25px;
  outline: none;
  overflow: hidden;
  /* padding: 0 12px; */
  position: relative;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  width: auto;
  padding: 20px 10px !important;
}
@media screen and (-ms-high-contrast: active) {
  .button {
    border: 2px solid windowText;
    color: windowText;
  }
}
.button.googleButton {
  font-size: 17px;
  height: 32px;
  letter-spacing: 0.25px;
  padding: 0 10px;
}
.button.purZT-SxQuSe {
  font-size: 11px;
  height: 20px;
  letter-spacing: 0.3px;
  padding: 0 8px;
}
.button.Bz112c-LgbsSe {
  padding: 0;
  width: 40px;
}
.button.Bz112c-LgbsSe.googleButton {
  width: 32px;
}
.button.Bz112c-LgbsSe.purZT-SxQuSe {
  width: 20px;
}
.button.JGcpL-RbRzK {
  -webkit-border-radius: 20px;
  border-radius: 20px;
}
.button.JGcpL-RbRzK.googleButton {
  -webkit-border-radius: 16px;
  border-radius: 16px;
}
.button.JGcpL-RbRzK.purZT-SxQuSe {
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
.button.googleColor {
  border: none;
  color: #fff;
}
.button.googleBackground {
  background-color: #1a73e8;
}
.button.MFS4be-JaPV2b-Ia7Qfc {
  background-color: #202124;
  color: #e8eaed;
}
.button .svgHolder {
  height: 18px;
  margin-right: 8px;
  min-width: 18px;
  width: 18px;
}
.button.googleButton .svgHolder {
  height: 14px;
  min-width: 14px;
  width: 14px;
}
.button.purZT-SxQuSe .svgHolder {
  height: 10px;
  min-width: 10px;
  width: 10px;
}
.button.jVeSEe .svgHolder {
  margin-left: 8px;
  margin-right: -4px;
}
.button.Bz112c-LgbsSe .svgHolder {
  margin: 0;
  padding: 10px;
}
.button.Bz112c-LgbsSe.googleButton .svgHolder {
  padding: 8px;
}
.button.Bz112c-LgbsSe.purZT-SxQuSe .svgHolder {
  padding: 4px;
}
.button .svgContainer {
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  background-color: #fff;
  height: 36px;
  margin-left: -10px;
  margin-right: 12px;
  min-width: 36px;
  width: 36px;
}
.button .svgContainer .svgHolder,
.button.Bz112c-LgbsSe .svgContainer .svgHolder {
  margin: 0;
  padding: 0;
}
.button.googleButton .svgContainer {
  /* height: 28px; */
  margin-left: -8px;
  margin-right: 10px;
  min-width: 28px;
  /* width: 28px; */
}
.button.purZT-SxQuSe .svgContainer {
  height: 16px;
  margin-left: -6px;
  margin-right: 8px;
  min-width: 16px;
  width: 16px;
}
.button.Bz112c-LgbsSe .svgContainer {
  -webkit-border-radius: 3px;
  border-radius: 3px;
  margin-left: 2px;
  margin-right: 0;
  padding: 0;
}
.button.JGcpL-RbRzK .svgContainer {
  -webkit-border-radius: 18px;
  border-radius: 18px;
}
.button.googleButton.JGcpL-RbRzK .svgContainer {
  -webkit-border-radius: 14px;
  border-radius: 14px;
}
.button.purZT-SxQuSe.JGcpL-RbRzK .svgContainer {
  -webkit-border-radius: 8px;
  border-radius: 8px;
}
.button .container {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-flex-direction: row;
  flex-direction: row;
  justify-content: space-between;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  height: 100%;
  position: relative;
  width: 100%;
}
.button .oXtfBe-l4eHX {
  justify-content: center;
}
.button .text {
  -webkit-flex-grow: 1;
  flex-grow: 1;
  font-family: 'Google Sans', arial, sans-serif;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
}
.button.purZT-SxQuSe .text {
  font-weight: 300;
}
.button .oXtfBe-l4eHX .text {
  -webkit-flex-grow: 0;
  flex-grow: 0;
}
.button .overlay {
  -webkit-transition: background-color 0.218s;
  transition: background-color 0.218s;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.button:hover,
.button:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #d2e3fc;
  outline: none;
}
.button:hover .overlay,
.button:focus .overlay {
  background: rgba(66, 133, 244, 0.04);
}
.button:active .overlay {
  background: rgba(66, 133, 244, 0.1);
}
.button.googleColor:hover .overlay,
.button.googleColor:focus .overlay {
  background: rgba(255, 255, 255, 0.24);
}
.button.googleColor:active .overlay {
  background: rgba(255, 255, 255, 0.32);
}
.button .n1UuX-DkfjY {
  -webkit-border-radius: 50%;
  border-radius: 50%;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  height: 20px;
  margin-left: -4px;
  margin-right: 8px;
  min-width: 20px;
  width: 20px;
}
.button.jVeSEe .text {
  font-family: 'Roboto';
  font-size: 12px;
  text-align: left;
}
.button.jVeSEe .text .ssJRIf,
.button.jVeSEe .text .K4efff .fmcmS {
  overflow: hidden;
  text-overflow: ellipsis;
}
.button.jVeSEe .text .K4efff {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  color: #5f6368;
  fill: #5f6368;
  font-size: 11px;
  font-weight: 400;
}
.button.jVeSEe.googleColor .text .K4efff {
  color: #e8eaed;
  fill: #e8eaed;
}
.button.jVeSEe .text .K4efff .Bz112c {
  height: 18px;
  margin: -3px -3px -3px 2px;
  min-width: 18px;
  width: 18px;
}
.button.jVeSEe .svgContainer {
  -webkit-border-top-left-radius: 0;
  border-top-left-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  border-bottom-left-radius: 0;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
  margin-left: 12px;
  margin-right: -10px;
}
.button.jVeSEe.JGcpL-RbRzK .svgContainer {
  -webkit-border-radius: 18px;
  border-radius: 18px;
}
.L5Fo6c-sM5MNb {
  border: 0;
  display: block;
  left: 0;
  position: relative;
  top: 0;
}
.L5Fo6c-bF1uUb {
  -webkit-border-radius: 4px;
  border-radius: 4px;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.L5Fo6c-bF1uUb:focus {
  border: none;
  outline: none;
}
sentinel {
}

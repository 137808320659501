.updatePasswordModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  max-height: max-content;
  width: 40rem;
  background-color: white;
  border-radius: 1rem;
  text-align: center;
  margin-bottom: 0;
  max-width: 95vw !important;
}

.updatePasswordModalHeader {
  margin: 4rem;
}

.updatePasswordModalForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.updateBtnDisabled {
  opacity: 0.5;
  cursor: default !important;
}

.updateBtnEnabled,
.updateBtnDisabled {
  text-decoration: none;
  padding: 0.95rem 2.75rem;
  max-width: 6em;
  font-size: 1.4rem;
  background-color: var(--color-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-white);
  border-radius: 0.5rem;
  cursor: pointer;
  box-shadow: 0 0.3rem 0.6rem rgba(var(--color-black-rgb), 0.2);
  margin: 1.5rem 1.5rem 0;
  outline: none;
  border: none;
  font-family: 'Roboto', 'sans-serif';
}

.updateBtn:hover {
  background-color: var(--color-primary-light);
}

.updateBtn:active {
  transition: 0.55ms;
  transform: scale(0.96);
}

.MuiInputBase-root-MuiOutlinedInput-root {
  padding-right: 0 !important;
}

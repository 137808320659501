.popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30rem;
    max-width: 95vw;
    height: max-content;
    background: #fff;
    border-radius: 1rem;
    box-shadow: 0 0.3rem 0.6rem rgba(var(--color-black-rgb), 0.2);
}

.listContainer {
    height: max-content;
    max-height: 24.9rem;
    overflow: auto;
}

.icon {
    font-size: 1.4rem;
    margin-right: 0.5rem;
}

.header {
    position: relative;
    height: 5rem;
    border-bottom: 2px solid var(--color-gray-light-1);
}

.header h4 {
    text-align: left;
    display: flex;
    align-items: center;
    padding: 1.8rem;
}

.heading {
    color: var(--color-gray-medium-2);
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    font-size: 1.4rem;
    font-weight: 500;
}

.item {
    height: 5rem;
    text-align: left;
    cursor: pointer;
    display: flex;
    vertical-align: middle;
    justify-content: space-between;
    padding: 0.6rem 0 0 1.4rem;
    align-items: center;
}

.item:hover {
    background-color: var(--color-gray-light-1);
  border-radius: 0 0 1rem 1rem;
}

.item:last-child:hover {
    background-color: var(--color-gray-light-1);
}

.item:not(:last-child) {
    border-bottom: 1px solid var(--color-gray-light-1);
}

.fileSize {
    font-size: 1.4rem;
    color: var(--color-gray-dark-2);
    font-weight: 300;
}

.icon {
    cursor: pointer;
    height: 2.8rem;
    width: 2.8rem;
    color: var(--color-gray-dark-3);
    padding: 0.5rem;
}

@media only screen and (max-width: 900px) {
    .popup {
        width: 35rem;
    }
}
.favouritesModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  width: 35rem;
  height: max-content;
  max-height: 35rem;
  background: #fff;
  border-radius: 1rem;
  box-shadow: 0 0.3rem 0.6rem rgba(var(--color-black-rgb), 0.2);
  max-width: 95vw !important;
  transition: all 0.2s ease;
}

.favouritesModalHeader {
  position: relative;
  height: 5rem;
  border-bottom: 2px solid var(--color-gray-light-1);
}

.favouritesModalHeading {
  text-align: left;
  align-items: middle;
  display: flex;
  align-items: center;
  padding: 1.5rem;
  color: var(--color-gray-medium-2);
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  font-size: 1.4rem;
  font-weight: 500;
}

.favouritesModalBody {
  height: max-content;
  max-height: 29.9rem;
  overflow-y: auto;
  overflow-x: hidden;
}

/* Featured creator header */
.header {
  padding: 1.2rem;
  border-bottom: 2px solid var(--color-gray-light-1);
}

.profileName {
  cursor: pointer;
}

.element {
  display: flex;
  align-items: center;
  padding: 1rem 1.2rem;
  height: 6rem;
  transition: all 0.2s;
}

.element:not(:last-child) {
  border-bottom: 1px solid var(--color-gray-light-1);
}

/* Featured Creators Avatar */
.avatar {
  margin-right: 1rem;
  cursor: pointer;
}

/* Featured Creators Text Wrapper */
.description {
  align-items: center;
  margin-right: auto;
}

.follow {
  cursor: pointer;
  height: 2.8rem;
  width: 2.8rem;
  color: var(--color-gray-dark-3);
  padding: 0.5rem;
  align-self: center;
  transition: all 0.2s ease;
}

/* .follow:hover {
  color: var(--color-gray-dark-1);
  padding: 0.4rem;
} */
/* .follow:active {
  color: var(--color-gray-dark-1);
  padding: 0.5rem;
} */

.fiUserCheck {
  cursor: pointer;
  height: 2.9rem;
  width: 2.9rem;
  color: var(--color-gray-dark-1);
  padding: 0.5rem;
  align-self: center;
  transition: all 0.2s ease;
}

/* .fiUserCheck:hover {
  color: var(--color-gray-dark-1);
  padding: 0.3rem;
} */

.confirmationButtonDiv {
  display: flex;
  justify-content: space-between;
  width: 25%;
}

.yesButton {
  color: var(--color-gray-medium-2);
}

.noButton {
  color: var(--color-primary);
}

.yesButton,
.noButton {
  padding: 0.5rem;
  cursor: pointer;
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 1.4rem;
  font-weight: 500;
  justify-content: center;
  text-align: center;
  transition: all 0.2s;
}

/* 
@keyframes swipeLeft {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
} */

/* Navbar Styles */
.navbar {
  position: sticky;
  min-width: 100%;
  top: 0;
  left: 0;
  padding: 0.45rem 2rem;
  background-color: var(--color-white);
  box-shadow: 0 0.3rem 1rem rgba(var(--color-black-rgb), 0.1);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
}


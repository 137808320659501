/* ******************************************CSS for desktop view****************************************** */

.mainDiv {
    height: calc(100vh - 5.6rem);
    display: flex;
    background-color: var(--color-white);
    padding: 0 5%;
}

.mainDivFirstTime {
    height: 100vh;
    display: flex;
    background-color: var(--color-white);
    padding: 0 5%;
}

.leftContainer {
    /* background-color: red; */
    width: 65%;
    padding: 2rem;
}

.searchContainer {
    margin: 2rem 0 4rem;
}

.creativeInterestHeading {
    color: var(--color-gray-dark-2);
    font-weight: 500;
    font-size: 1.6rem;
    text-transform: uppercase;
}

.popularCreativeInterestsContainer {
    padding: 1rem 0 0;
    height: calc(100vh - 22rem);
    overflow-y: scroll;
}

.popularCreativeInterestsContainerFirstTime {
    padding: 1rem 0 0;
    height: calc(100vh - 16.5rem);
    overflow-y: scroll;
}

.creativeInterestCardContainer {
    height: 4rem;
    text-transform: capitalize;
    box-shadow: 0 0.3rem 0.6rem rgba(var(--color-black-rgb), 0.2);
    background: var(--color-white);
    width: 98%;
    margin: 0.25rem;
    border-radius: 0.5rem;
    max-height: 40px;
    padding: 0 3%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.creativeInterestCardText {
    color: var(--color-gray-medium-3);
    font-size: 1.6rem;
    font-weight: 400;
}

.addcategoryIconDiv {
    display: flex;
    justify-content: center;
    align-items: center;
}

.rightContainer {
    /* background-color: yellowgreen; */
    width: 35%;
    padding: 2rem;
}

.avatarContainer {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
}

.avatar {
    width: 8rem;
    height: 8rem;
    border: 4px solid var(--color-white);
    box-shadow: 0 0.3rem 0.6rem rgba(var(--color-black-rgb), 0.2);
    border-radius: 100%;
    object-fit: cover;
}

.nameContainer {
    padding-left: 1.4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.creativeUserName {
    font-size: 2rem;
    font-weight: 500;
    color: var(--color-gray-dark-1);
}

.creativeUserTopInterest {
    font-size: 1.4rem;
    color: var(--color-gray-medium-3);
    margin-bottom: 0;
    text-transform: capitalize;
}

/* .myCreativeInterestsContainer{

} */

.creativeMyInterestIcons {
    display: flex;
    align-items: center;
}

.creativeMyInterestZap {
    color: var(--color-gray-medium-3);
    cursor: pointer;
    border: none;
}

.creativeCursorPointer {
    color: var(--color-gray-medium-3);
    display: flex;
    font-size: 2rem;
    margin: 0.5rem 0.25rem;
    cursor: pointer;
}

.interestsCardContainer {
    padding: 1rem 0 0;
    height: calc(100vh - 26.8rem);
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.interestsCardContainerFirstTime {
    padding: 1rem 0 0;
    height: calc(100vh - 21.2rem);
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}

.creativeRightContainerBottomHeading {
    color: var(--color-gray-medium-2);
    text-align: center;
    font-size: 1.4rem;
    font-weight: 400;
    bottom: 0;
}

.creativeHomeBtnDisabled {
    opacity: 0.5;
    cursor: not-allowed !important;
}

.creativeHomeBtnEnabled,
.creativeHomeBtnDisabled {
    text-decoration: none;
    margin: 0 auto;
    width: 100%;
    padding: 1rem 2.75rem;
    font-size: 1.6rem;
    background-color: var(--color-primary);
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-white);
    border-radius: 0.5rem;
    cursor: pointer;
    box-shadow: 0 0.3rem 0.6rem rgba(var(--color-black-rgb), 0.2);
    outline: none;
    border: none;
    font-family: 'Roboto', 'sans-serif';
}

@media (min-width: 768px) and (max-width: 900px) {
    .leftContainer {
        width: 60%;
    }

    .rightContainer {
        width: 40%;
    }

    .popularCreativeInterestsMobileContainer {
        height: 55vh;
        overflow: auto;
        /* overflow-y: scroll; */
    }
}

/* ******************************************CSS for mobile view ****************************************** */
.mainMobileDiv {
    background-color: var(--color-white);
    height: calc(100vh - 5.6rem);
    padding: 2rem 10rem;
}

.mainMobileDivFirstTime {
    background-color: var(--color-white);
    height: 100vh;
    padding: 2rem 10rem;
}

.searchMobileContainer {
    display: flex;
}

.mobileTabs {
    margin: 2rem 0;
    display: flex;
    justify-content: space-evenly;
}

.creativeInterestMobileHeadingEnabled {
    font-size: 1.6rem;
    text-transform: capitalize;
    color: var(--color-gray-dark-1);
    font-weight: 450;
    cursor: pointer;
}

.creativeInterestMobileHeadingDisabled {
    font-size: 1.6rem;
    text-transform: capitalize;
    color: var(--color-gray-medium-2);
    font-weight: 450;
    cursor: pointer;
}

.vl {
    border-left: 0.1rem solid var(--color-gray-medium-2);
}

.interestsMobileContainer {
    /* height: 55vh; */
    height: calc(100vh - 33rem);
}

.interestsMobileContainerFirstTime {
    /* height: 55vh; */
    height: calc(100vh - 30rem);
}

.popularCreativeInterestsMobileContainer {
    /* height: 55vh; */
    height: calc(100vh - 33.2rem);
    overflow: auto;
    /* background-color: blue; */
    /* overflow-y: scroll; */
}

.popularCreativeInterestsMobileContainerFirstTime {
    height: calc(100vh - 30.2rem);
    overflow: auto;
}

.myCreativeInterestsMobileContainer {
    height: calc(100vh - 33.2rem);
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.myCreativeInterestsMobileContainerFirstTime {
    height: calc(100vh - 30.2rem);
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}


/* @media only screen and (max-height: 660px) and (max-width: 600px) {
  .mainMobileDiv {
    height: calc(100vh - 11rem);
    padding: 2rem;
  }

  .interestsMobileContainer {
    height: 48vh !important;
  }

  .popularCreativeInterestsMobileContainer {
    height: 100%;
    overflow: auto;
  
  }

  .myCreativeInterestsMobileContainer {
    height: 100%;
    overflow: auto;
  }
} */

@media only screen and (max-width: 600px) {

    /* @media only screen and (min-height: 660px) and (max-width: 600px) { */
    .mainMobileDiv {
        height: calc(100vh - 11rem);
        padding: 2rem;
    }

    .mainMobileDivFirstTime {
        height: calc(100vh);
        padding: 2rem;
    }

    .interestsMobileContainer {
        /* background-color: red; */
        /* height: 53vh; */
        height: calc(100vh - 38rem);
        /* height: calc(100vh - 44rem); */
    }

    /*.interestsMobileContainerFirstTime {*/
    /*    height: calc(100vh - 20rem);*/
    /*}*/

    .popularCreativeInterestsMobileContainer {
        height: calc(100vh - 38.2rem);
        overflow: auto;
    }

    .popularCreativeInterestsMobileContainerFirstTime {
        height: calc(100vh - 30rem);
        overflow: auto;
    }

    .myCreativeInterestsMobileContainer {
        height: calc(100vh - 38.2rem);
        overflow: auto;
    }

    .myCreativeInterestsMobileContainerFirstTime {
        height: calc(100vh - 30rem);
        overflow: auto;
    }
}

/* @media only screen and (min-resolution: 150dpi) { */

@media only screen and (-webkit-min-device-pixel-ratio: 1.5) {
    .mainMobileDiv {
        height: calc(100vh - 11rem);
        padding: 2rem;
    }

    .mainMobileDivFirstTime {
        height: 100vh;
        padding: 2rem;
    }

    .interestsMobileContainer {
        height: calc(100vh - 44rem);
    }

    .interestsMobileContainerFirstTime {
        height: calc(100vh - 34rem);
    }

    .popularCreativeInterestsMobileContainer {
        height: calc(100vh - 44rem);
        overflow: auto;
    }

    .popularCreativeInterestsMobileContainerFirstTime {
        height: calc(100vh - 34.2rem);
        overflow: auto;
    }

    .myCreativeInterestsMobileContainer {
        height: calc(100vh - 44rem);
        overflow: auto;
    }

    .myCreativeInterestsMobileContainerFirstTime {
        height: calc(100vh - 34.2rem);
        overflow: auto;
    }
}

.beat {
    margin-left: 1rem;
    animation: beat 0.5s infinite alternate;
    transform-origin: center;
}

@keyframes beat {
    to {
        transform: scale(1.15);
    }
}
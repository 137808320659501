.thirdModal {
  position: absolute;
  top: 50%;
  left: 50%;
  background: var(--color-white);
  transform: translate(-50%, -50%) !important;
  width: 45rem;
  max-width: 95vw !important;
  min-height: max-content;
  border-radius: 1rem;
  box-shadow: 0 0.3rem 0.6rem rgba(var(--color-black-rgb), 0.2);
}
.icon {
  margin: 0;
  margin-right: 0.5rem;
  fill: darkgoldenrod;
}
.thirdModalTitle {
  color: red;
  text-align: justify;
  margin-bottom: 1rem;
}
.thirdModalTitle2 {
  font-size: 1.8rem;
  line-height: 2rem;
  color: red;
  text-align: center;
  font-weight: 450;
  margin-top: 2rem;
}
.thirdModalBody {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 4rem;
}
.buttons1 {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding: 1.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0;
}
.changeMyMindButton {
  text-decoration: none;
  width: 40%;
  padding: 1.15rem 2.5rem;
  font-size: 1.4rem;
  background-color: darkgreen;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-white);
  border-radius: 0.5rem;
  cursor: pointer;
  box-shadow: 0 0.3rem 0.6rem rgba(var(--color-black-rgb), 0.2);
  margin: 1rem 0.5rem;
  margin-bottom: 0;
  outline: none;
  border: none;
  font-family: 'Roboto', 'sans-serif';
}
.changeMyMindButton:hover {
  background-color: green;
}
.changeMyMindButton:active {
  transition: 0.55ms;
  transform: scale(0.96);
}
.deleteMyAccountButtonDisabled {
  opacity: 0.5;
  cursor: default !important;
}
.deleteMyAccountButtonEnabled,
.deleteMyAccountButtonDisabled {
  text-decoration: none;
  width: 40%;
  padding: 1.15rem;
  font-size: 1.4rem;
  background-color: rgb(205, 0, 26);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-white);
  border-radius: 0.5rem;
  cursor: pointer;
  box-shadow: 0 0.3rem 0.6rem rgba(var(--color-black-rgb), 0.2);
  margin: 1rem 0.5rem;
  margin-bottom: 0;
  outline: none;
  border: none;
  font-family: 'Roboto', 'sans-serif';
}

.deleteMyAccountButton:hover {
  background-color: rgb(237, 29, 36);
}
.deleteMyAccountButton:active {
  transition: 0.55ms;
  transform: scale(0.96);
}

.speedDial {
  position: absolute;
  left: 0;
  bottom: 1rem;
}

.action button {
  /* background-color: var(--color-black) !important; */
  background-color: var(--color-white);
}
.tooltip {
  color: var(--color-gray-medium-3);
  background-color: #fff;
}

.mainDiv {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.flexContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.homeButton {
    text-decoration: none;
    padding: 1.5rem 2.5rem;
    font-size: 1.4rem;
    background-color: var(--color-primary);
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-white);
    border-radius: 0.5rem;
    cursor: pointer;
    box-shadow: 0 0.3rem 0.6rem rgba(var(--color-black-rgb), 0.2);
    margin: 1.5rem;
    outline: none;
    border: none;
    font-family: 'Roboto', 'sans-serif';
}

.homeButton:hover {
    background-color: var(--color-primary-light);
}

.homeButton:active {
    transition: 0.55ms;
    transform: scale(0.96);
}
.slide {
  display: flex;
  width: 100%;
  height: 100%;
  flex-grow: 1;
}
.img {
  width: 100%;
  /*aspect-ratio: 3/2;*/
  object-fit: cover;
}
.video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.listItem {
  display: flex;
  background-color: black;
}

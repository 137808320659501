.leftArrow {
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 2%;
  transform: translateY(-50%);
  background-color: var(--color-white);
  border: none;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
  box-shadow: 0 0.8rem 1.6rem rgba(var(--color-black-rgb), 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}
.rightArrow {
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 2%;
  transform: translateY(-50%);
  background-color: var(--color-white);
  border: none;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
  box-shadow: 0 0.8rem 1.6rem rgba(var(--color-black-rgb), 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}
.chevron {
  position: relative;
  color: var(--color-gray-dark-2);
}

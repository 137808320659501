.dropdown {
  position: absolute;
  top: 6.5rem;
  right: 1rem;
  min-width: 25rem;
  border-radius: 1rem;
  box-shadow: 0 0.8rem 1.6rem rgba(0, 0, 0, 0.1);
  background-color: var(--color-white);
  overflow: hidden;
  transition: all 0.2s;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.2rem;
  border-bottom: 0.2rem solid var(--color-gray-light-1);
}

.fix {
  cursor: pointer;
  background: none;
  border: none;
  font-size: 2rem;
  color: var(--color-gray-medium-2);
  display: none;
}

.heading {
  color: var(--color-gray-medium-2);
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  font-size: 1.4rem;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}

.item {
  /* margin-bottom: 2px; */
  padding: 1.2rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  /* transition: all 0.2s !important; */
}

.item:hover {
  background-color: var(--color-gray-light-1);
}

.item:not(:last-child) {
  border-bottom: 1px solid var(--color-gray-light-1);
}

.icon {
  margin-right: 1rem;
  color: var(--color-gray-dark-2);
  height: 1.8rem;
  width: 1.8rem;
  /* margin-top: 2px; */
}

.text {
  font-size: 1.4rem;
  color: var(--color-gray-dark-2);
  font-weight: 500;
}

@media only screen and (max-width: 600px) {
  .dropdown {
    position: fixed;
    top: unset;
    right: unset;
    bottom: 48px;
    left: 50%;
    transform: translateX(-50%) !important;
    width: 100%;
    border-radius: 1rem 1rem 0 0;
    box-shadow: -0.8rem -0.8rem 1.6rem rgba(0, 0, 0, 0.1);
    /* box-shadow: none; */
    
    /* zoom: 120%; */
  }
  .element-profile {
    display: none;
  }

  /* .container {
    min-height: 28rem !important;
  } */

  .header {
    padding: 1rem 2rem;
    margin-top: 5px;
  }
  .fix {
    color: var(--color-gray-medium-3);
    display: visible;
  }
}

.myContent {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-gray-medium-2);
  font-size: 1.6rem;
}
.myContentActive {
  color: var(--color-gray-dark-1);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
}
.savedContent {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-gray-medium-2);
  font-size: 1.6rem;
}
.savedContentActive {
  color: var(--color-gray-dark-1);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
}
.gridIconPost {
  display: inline;
  align-items: center;
  justify-content: center;
  font-size: 2.2rem;
  margin: 0;
  margin-right: 0.5rem;
  color: var(--color-gray-medium-2);
  stroke-width: 1.25;
}
.gridIconPostActive {
  fill: var(--color-gray-dark-1);
  color: var(--color-gray-dark-1);
  display: inline;
  font-size: 2.2rem;
   margin: 0;
  margin-right: 0.5rem;
}
.bookmarkIconPost {
  display: inline;
  align-items: center;
  justify-content: center;
  font-size: 2.2rem;
  margin: 0;
  margin-right: 0.5rem;
  color: var(--color-gray-medium-2);
  line-height: 2;
  font-weight: 100;
  stroke-width: 1.25;
}
.bookmarkIconPostActive {
  fill: var(--color-gray-dark-1);
  color: var(--color-gray-dark-1);
  display: inline;
  font-size: 2.2rem;
  margin: 0;
  margin-right: 0.5rem;
}
.savedContentBar {
  cursor: pointer;
  display: inline-flex;
  margin: 0 1.5rem;
  margin-left: 0;
  padding: 0 2rem;
  padding-left: 1.4rem;
  justify-content: center;
  margin-top: 1rem;
  border-left: 0.1rem solid var(--color-gray-light-3);
}
/* .myContentBar p {
  margin: auto 0;
}
.savedContentBar p {
  margin: auto 0;
} */

@media screen and (max-width: 900px) {
  .myContent {
    font-size: 1.6rem;
  }
  .myContentActive {
    font-size: 1.6rem;
  }
  .savedContent {
    font-size: 1.6rem;
  }
  .savedContentActive {
    font-size: 1.6rem;
  }
  .gridIconPost {
    font-size: 2.2rem;
    /* margin: 0 0.5rem; */
    stroke-width: 1.25;
  }
  .gridIconPostActive {
    font-size: 2.2rem;
    /* margin: 0 0.5rem; */
  }
  .bookmarkIconPost {
    font-size: 2.2rem;
    margin: 0 0.5rem;
    color: var(--color-gray-medium-2);
    line-height: 2;
    font-weight: 100;
    stroke-width: 1.25;
  }
  .bookmarkIconPostActive {
    font-size: 2.2rem;
    margin: 0 0.5rem;
  }
  .savedContentBar {
    margin: 0 1.5rem;
    margin-left: 0;
    padding: 0 2rem;
    padding-left: 1rem;
    margin-top: 1rem;
    border-left: 0.1rem solid var(--color-gray-light-3);
  }
}
@media screen and (max-width: 600px) {
  .myContent {
    font-size: 3.5vw;
  }
  .myContentActive {
    font-size: 3.5vw;
  }
  .savedContent {
    font-size: 3.5vw;
  }
  .savedContentActive {
    font-size: 3.5vw;
  }
  .gridIconPost {
    font-size: 5vw;
    margin: 0 1vw;
    stroke-width: 1.25;
  }
  .gridIconPostActive {
    font-size: 5vw;
    margin: 0 1vw;
  }
  .bookmarkIconPost {
    font-size: 5vw;
    margin: 0 1vw;
    color: var(--color-gray-medium-2);
    line-height: 2;
    font-weight: 100;
    stroke-width: 1.25;
  }
  .bookmarkIconPostActive {
    font-size: 5vw;
    margin: 0 1vw;
  }
  .savedContentBar {
    margin: 0 2vw;
    margin-left: 0;
    padding: 0 2.5vw;
    padding-left: 1.5vw;
    border-left: 0.1rem solid var(--color-gray-light-3);
  }
}

.main {
    /* width: 46.5%; */
    /* height: 100vh; */
    margin: 0 2rem;
    max-width: 560px;
}

.eventCardSection {
    height: 21rem;
    margin-bottom: 2rem;
}

.eventCard {
    height: 21rem;
}

@media only screen and (max-width: 1200px) {
    .main {
        margin-right: 0;
        min-width: 560px;
    }
}

@media only screen and (max-width: 900px) {
    .main {
        width: 560px;
        margin: 0;
        /* max-width: 60vw; */
    }
}

@media only screen and (max-width: 600px) {
    .main {
        width: 100vw;
        min-width: 100vw;
        max-width: 560px;
        margin: 0;
    }

    .eventCardSection {
        height: 37vw;
        margin-bottom: 2vw;
    }

    .eventCard {
        height: 37vw;
    }
    .lastDiv{
        height: 4.8rem;
    }
}
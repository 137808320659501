.editPasswordModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  height: fit-content;
  width: 45rem;
  min-height: 26.6rem;
  background-color: white;
  border-radius: 1rem;
  text-align: center;
  margin-bottom: 0;
  max-width: 95vw !important;
}

.notAllowedModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  width: 30rem;
  background: #fff;
  border-radius: 1rem;
  box-shadow: 0 0.3rem 0.6rem rgba(var(--color-black-rgb), 0.2);
  max-width: 95vw;
}

.notAllowedText {
  color: var(--color-gray-medium-3);
  font-size: 1.4rem;
  text-align: center;
  margin: 3rem;
}

.box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4rem 0 0;
}

.formEditPassword {
  margin: auto 0 !important;
  width: 100% !important;
}

.buttonUpdate {
  display: flex;
  justify-content: center;
  align-items: center;
}

.updateButtonDisabled {
  opacity: 0.5;
  cursor: default !important;
}

.updateButtonEnabled,
.updateButtonDisabled {
  text-decoration: none;
  padding: 1.15rem 2.5rem;
  font-size: 1.4rem;
  background-color: var(--color-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-white);
  border-radius: 0.5rem;
  cursor: pointer;
  box-shadow: 0 0.3rem 0.6rem rgba(var(--color-black-rgb), 0.2);
  margin: 1.5rem;
  outline: none;
  border: none;
  font-family: 'Roboto', 'sans-serif';
}

.updateButtonEnabled:hover {
  background-color: var(--color-primary-light);
}

.updateButtonEnabled:active {
  transition: 0.55ms;
  transform: scale(0.96);
}

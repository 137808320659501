/* Dropdown element */
.element {
  display: flex;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;
  height: 4.5rem;
  cursor: pointer;
  border-bottom: 1px solid var(--color-gray-light-1);
}

.element:hover {
  background-color: var(--color-gray-light-1);
}

/* Element icon */
.icon,
.flaggedIcon {
  height: 1.8rem;
  width: 1.8rem;
  color: var(--color-gray-dark-2);
  margin-right: 1rem;
  stroke-width: 2px;
}
.flaggedIcon {
  color: var(--color-primary);
  fill: var(--color-primary);
}

/* ? Modal Styles */

/* Modal container */
.modalContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  height: 30rem;
  width: 45rem;
  background: #fff;
  border-radius: 1rem;
  box-shadow: 0 0.3rem 0.6rem rgba(var(--color-black-rgb), 0.2);
  max-width: 95vw !important;
}

/* Deactivating default focus styles by material ui */
.modalContainer:focus {
  outline: none !important;
  border: none !important;
}

/* Modal heading */
.heading {
  margin: 0;
  color: var(--color-gray-medium-3);
}

.reportForm {
  margin: 3rem;
}

.reportTextArea {
  position: relative;
  height: 16.5rem;
  width: 100%;
  border-radius: 0.5rem;
  resize: none;
  padding: 1rem;
  margin: 1rem 0;
  border: 1px solid var(--color-gray-medium-2);
}
.reportTextArea:focus {
  outline: none;
}

.buttonBox {
  display: flex;
  justify-content: center;
  align-items: center;
}
.reportButtonEnabled,
.reportButtonDisabled {
  text-decoration: none;
  padding: 1rem 2.75rem;
  max-width: 6em;
  font-size: 14px;
  background-color: var(--color-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-white);
  border-radius: 0.5rem;
  cursor: pointer;
  box-shadow: 0 0.3rem 0.6rem rgba(var(--color-black-rgb), 0.2);
  /* margin: 1.9rem; */
  outline: none;
  border: none;
  font-family: 'Roboto', 'sans-serif';
}
.reportButtonDisabled {
  opacity: 0.5;
  cursor: default !important;
}

.reportButtonEnabled:hover {
  background-color: var(--color-primary-light);
}
.reportButtonEnabled:active {
  transition: 0.55ms;
  transform: scale(0.96);
}
@media only screen and (max-width: 900px) {
  .heading {
    color: var(--color-gray-dark-2);
  }
  .reportTextArea {
    border: 1px solid var(--color-gray-medium-3);
  }
  .cross-button {
    color: var(--color-gray-medium-3) !important;
  }
}

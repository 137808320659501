.notificationContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all 0.2s;
    cursor: pointer;
    padding: 1rem 1.2rem;
}

.notificationContainer:not(:last-child) {
    border-bottom: 1px solid var(--color-gray-light-1);
}

.notificationContainer:hover {
    background-color: var(--color-gray-light-1);
}

.textContainer {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-right: 2rem;
}

.text {
    margin-left: 1rem;
    margin-right: auto;
}

.iconContainer {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.name {
    font-weight: 500;
    font-size: 1.4rem;
    color: var(--color-gray-dark-2);
}

.message {
    font-size: 1.4rem;
    color: var(--color-gray-medium-2);
}

@media only screen and (max-width: 900px) {
    .name {
        color: var(--color-gray-dark-1);
    }

    .message {
        color: var(--color-gray-medium-3);
    }
}
/* Dropdown element */
.element {
  display: flex;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;
  height: 4.5rem;
  cursor: pointer;

  /* display: flex;
  align-items: center;
  padding: 1.1rem 1.3rem;
  cursor: pointer;
  transition: all 0.2s; */
}

.element:hover {
  background-color: var(--color-gray-light-1);
}

/* Element icon */
.icon {
  height: 1.8rem;
  width: 1.8rem;
  color: var(--color-gray-dark-2);
  margin-right: 1rem;
  stroke-width: 2px;
  /* margin-top: -15%; */
}

/* Confirmation Container */
.confirmationContainer {
  display: flex;
  height: 4rem;
}

/* confirmation actions */
.confirmDelete,
.cancelDelete {
  height: 4rem;
  font-size: 1.4rem;
  font-weight: 500;
  color: var(--color-gray-medium-2);
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s;
}

.confirmDelete:hover,
.cancelDelete:hover {
  background-color: var(--color-gray-light-1);
}

/* Cancel Delete */
.cancelDelete {
  color: var(--color-primary);
}

.closeWarningModal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    width: 31rem;
    height: 15rem;
    background-color: #fff;
    border-radius: 1rem;
    max-width: 90vw !important;
}

.closeWarningModalMain {
    margin: 3rem;
    justify-content: center;
    align-items: center;
}

.closeWarningModalHeading {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.closeWarningModalHeading h3 {
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 2rem;
    color: var(--color-gray-medium-2) !important;
}

.closeWarningModalHeading p {
    margin-top: 0.5rem;
    font-size: 1.4rem;
    /*font-weight: 500;*/
    line-height: 2rem;
    color: var(--color-gray-medium-2) !important;
}

.closeWarningModalBody {
    display: flex;
    justify-content: center;
    align-items: center;
}

.yesButton,
.noButton {
    text-decoration: none;
    padding: 1.15rem 2.5rem;
    font-size: 1.4rem;
    background-color: var(--color-gray-medium-2);
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-white);
    border-radius: 0.5rem;
    cursor: pointer;
    box-shadow: 0 0.3rem 0.6rem rgba(var(--color-black-rgb), 0.2);
    margin: 1.5rem;
    justify-self: center;
    align-self: center;
    outline: none;
    border: none;
    font-family: 'Roboto', 'sans-serif';
}

.yesButton:hover {
    background-color: var(--color-gray-medium-1);
}

.yesButton:active {
    transition: 0.55ms;
    transform: scale(0.96);
}

.noButton {
    width: 7.5rem;
    background-color: var(--color-primary);
    color: var(--color-white);
}

.noButton:hover {
    background-color: var(--color-primary-light);
}

.noButton:active {
    transition: 0.55ms;
    transform: scale(0.96);
}

@media only screen and (max-width: 900px) {
    .closeWarningModalHeading h3 {
        color: var(--color-gray-medium-3) !important;
    }
}
.helpdeskModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  width: 50rem;
  height: fit-content;
  background: #fff;
  border-radius: 1rem;
  box-shadow: 0 0.3rem 0.6rem rgba(var(--color-black-rgb), 0.2);
  max-width: 95vw !important;
}
.helpdesk-modal-heading {
  padding: 2rem 3rem;
}
.form-helpdesk-modal {
  padding: 0 3rem;
}
.formContainer {
  margin: 3rem;
  margin-bottom: 2rem;
}

::placeholder {
  color: var(--color-gray-medium-2);
}
.helpdeskModalTitle {
  margin-bottom: 1rem;
  color: var(--color-gray-medium-3);
}
.helpdeskTextArea {
  position: relative;
  height: 17.5rem;
  width: 100%;
  border-radius: 0.5rem;
  resize: none;
  padding: 1rem 1rem;
  border: 1px solid var(--color-gray-medium-2);
}

.helpdeskTextArea:focus {
  outline: none;
}

.helpdeskSendButtonBox {
  display: flex;
  justify-content: center;
  align-items: center;
}
.helpdeskSendButtonDisabled {
  opacity: 0.5;
  cursor: default !important;
}
.helpdeskSendButtonEnabled,
.helpdeskSendButtonDisabled {
  text-decoration: none;
  padding: 1.15rem 2.75rem;
  font-size: 1.4rem;
  background-color: var(--color-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-white);
  border-radius: 0.5rem;
  cursor: pointer;
  box-shadow: 0 0.3rem 0.6rem rgba(var(--color-black-rgb), 0.2);
  margin: 1.9rem;
  margin-bottom: 0;
  outline: none;
  border: none;
  font-family: 'Roboto', 'sans-serif';
}

.helpdeskSendButtonEnabled:hover {
  background-color: var(--color-primary-light);
}
.helpdeskSendButtonEnabled:active {
  transition: 0.55ms;
  transform: scale(0.96);
}

@media only screen and (max-width: 900px) {
  .helpdeskModalTitle {
    color: var(--color-gray-dark-2);
  }
  .helpdeskTextarea {
    border: 1px solid var(--color-gray-medium-3);
  }
}

.bannerDropdown {
  position: absolute;
  position: relative;
  left: 41.25vw;
  bottom: 4.75vw;
}
.main {
  margin: 0;
  padding: 0;
  height: 100%;
}
.EditProfileSection {
  background-color: var(--color-white);
  display: block;
  margin: auto;
  width: 90rem;
  height: auto;
  box-shadow: 0 0.3rem 0.6rem rgba(var(--color-black-rgb), 0.2);
}

.bannerEp {
  display: block;
  height: max-content;
}
.editBanner {
  height: max-content;
}

.editBanner img {
  width: 90rem;
  aspect-ratio: 3;
  overflow: hidden;
  object-fit: cover;
}
.editImgAvt1 img {
  height: 18rem;
  width: 18rem;
  margin-left: 3rem;
  margin-top: -16.5rem;
  padding: 0.8rem;
  border-radius: 100%;
  background: var(--color-white);
  object-fit: cover;
  box-shadow: 0 0.3rem 0.6rem rgba(var(--color-black-rgb), 0.2);
}
.cameraIcon1 {
  position: relative;
  bottom: 6.1rem;
  left: 82.5rem;
  background-color: var(--color-gray-medium-3);
  box-shadow: 0 0.3rem 0.6rem rgba(var(--color-black-rgb), 0.2);
  border-radius: 100%;
  height: 4.5rem;
  width: 4.5rem;
  padding: 0.5rem;
  color: var(--color-white);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.cameraIcon1:hover {
  background-color: var(--color-gray-dark-2);
}
.cameraIcon1:active {
  transition: 0.55ms;
  transform: scale(0.96);
}
.cameraDropdownContainer {
  position: relative;
  margin-right: 2rem;
}
.cameraIcon2 {
  position: relative;
  color: var(--color-white);
  background-color: var(--color-gray-medium-3);
  box-shadow: 0 0.3rem 0.6rem rgba(var(--color-black-rgb), 0.2);
  border-radius: 50%;
  height: 4.5rem;
  width: 4.5rem;
  padding: 0.5rem;
  color: var(--color-white);
  left: 16.8rem;
  bottom: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.cameraIcon2:hover {
  background-color: var(--color-gray-dark-2);
}
.cameraIcon2:active {
  transition: 0.55ms;
  transform: scale(0.96);
}
.fillCameraIcon {
  height: 90%;
  width: 90%;
}
.editBanner p {
  font-size: 1.6rem;
  position: relative;
  color: var(--color-white);
  background-color: var(--color-gray-medium-3);
  border-radius: 0.5rem;
  width: max-content;
  padding: 0.6% 1%;
  bottom: 10rem;
  left: 57.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.editImgAvt1 {
  margin-top: -1rem;
}
.bottomTextFieldsContainer {
  margin-top: -3rem;
  /* background-color: aquamarine; */
  padding: 0 5%;
}
.names {
  display: flex;
  justify-content: space-evenly;
  gap: 2%;
}
.textCheckOne {
  width: 100%;
  margin: 1.5rem 0;
}
.textCheckTwo {
  width: 100%;
  margin: 1.5rem 0;
}
.textCheckThree {
  height: 20rem;
  width: 100%;
  border-radius: 0.5rem;
  border: 0.1rem solid var(--color-gray-medium-3);
  font-size: 1.6rem;
}
.textCheckThree:hover {
  border: 0.1rem solid var(--color-gray-medium-3);
}
.textCheckThree:focus {
  border: 0.1rem solid var(--color-gray-dark-2);
}
.impactHeading {
  text-align: justify;
  margin: 1rem 0;
}
.btnContainer {
  width: 81rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.btnSaveDisabled {
  opacity: 0.5;
  cursor: default !important;
}
.btnSaveDisabled,
.btnSave {
  text-decoration: none;
  padding: 1.15rem 2.75rem;
  font-size: 1.4rem;
  background-color: var(--color-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-white);
  border-radius: 0.5rem;
  cursor: pointer;
  box-shadow: 0 0.3rem 0.6rem rgba(var(--color-black-rgb), 0.2);
  outline: none;
  border: none;
  font-family: 'Roboto', 'sans-serif';
}
.btnSave:hover {
  background-color: var(--color-primary-light);
}
.btnSave:active {
  transition: 0.55ms;
  transform: scale(0.96);
}

.btnCancel {
  text-decoration: none;
  margin-top: 2em;
  padding: 1.15rem 2.1rem;
  font-size: 1.4rem;
  background-color: var(--color-gray-medium-2);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-white);
  border-radius: 0.5rem;
  cursor: pointer;
  box-shadow: 0 0.3rem 0.6rem rgba(var(--color-black-rgb), 0.2);
  margin: 1.5rem;
  margin-left: 0;
  justify-self: center;
  align-self: center;
  outline: none;
  border: none;
  font-family: 'Roboto', 'sans-serif';
}
.btnCancel:hover {
  background-color: var(--color-gray-medium-1);
}
.btnCancel:active {
  transition: 0.55ms;
  transform: scale(0.96);
}
.endOfForm {
  height: 5px;
}

@media screen and (max-width: 900px) {
  .EditProfileSection {
    background-color: var(--color-white);
    display: block;
    margin: auto;
    width: 60rem;
    height: fit-content;
    border-radius: 0;
    box-shadow: none;
  }

  .editBanner img {
    width: 60rem;
    aspect-ratio: 3;
    overflow: hidden;
    background-color: #ccc;
  }
  .editImgAvt1 {
    margin-top: -2rem;
  }
  .editImgAvt1 img {
    height: 14.4rem;
    width: 14.4rem;
    margin-left: 3rem;
    box-shadow: 0 0.3rem 0.6rem rgba(var(--color-black-rgb), 0.2);
    border-radius: 100%;
    /* padding: 0.5rem; */
  }
  .editBanner p {
    font-size: 1.6rem;
    left: 29.1rem;
    bottom: 9.2rem;
    border-radius: 0.5rem;
  }
  .cameraIcon1 {
    height: 4rem;
    width: 4rem;
    left: 54rem;
    bottom: 5.7rem;
    padding: 0.5rem;
  }
  .cameraIcon2 {
    height: 4rem;
    width: 4rem;
    left: 13.8rem;
    bottom: 6rem;
    padding: 0.5rem;
  }

  .textCheckOne {
    margin: 1rem 0;
  }
  .textCheckTwo {
    margin: 1rem 0;
  }
  .textCheckThree {
    height: 18rem;
    width: 100%;
    border: 0.1rem solid var(--color-gray-dark-2);
  }
  .textCheckThree:hover {
    border: 0.1rem solid var(--color-gray-dark-2);
  }
  .textCheckThree:focus {
    border: 0.1rem solid var(--color-gray-dark-1);
  }

  .endOfForm {
    height: 55px;
  }
  .btnContainer {
    width: 100%;
  }
}
@media screen and (max-width: 600px) {
  .EditProfileSection {
    background-color: var(--color-white);
    display: block;
    margin: auto;
    width: 100%;
    height: fit-content;
    border-radius: 0;
    box-shadow: none;
  }

  .editBanner img {
    width: 100%;
    background-color: #ccc;
  }
  .editImgAvt1 img {
    position: relative;
    height: 27vw;
    width: 27vw;
    margin-left: 3.4vw;
    margin-top: -25vw;
    padding: 1.2vw;
  }
  .editBanner p {
    font-size: 3vw;
    bottom: 17.2vw;
    left: 41.1vw;
  }
  .cameraIcon1 {
    height: 8vw;
    width: 8vw;
    bottom: 10.6vw;
    left: 87vw;
  }
  .cameraIcon2 {
    height: 8vw;
    width: 8vw;
    left: 23.5vw;
    bottom: 11vw;
  }

  .textCheckOne {
    margin: 1rem 0;
  }
  .textCheckTwo {
    margin: 1rem 0;
  }
  .textCheckThree {
    height: 20rem;
  }

  .endOfForm {
    height: 55px;
  }
  .btnContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.mainDiv {
  display: flex;
  flex-grow: 1;
}

.mainDiv video {
    width: 100%;
    height: 100%;
    object-fit: contain; 
    display: flex;
    flex-grow: 1;
}

.pause {
  border: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 3em;
  left: 3em;
  opacity: 0;
}
.play {
  border: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 3em;
  left: 3em;
  background: radial-gradient(circle at center, white, rgba(255, 255, 255, 0) 13%);
}
.playpauseIcon {
  height: 6em;
  width: 6em;
}

.controls {
  position: absolute;
  bottom: 1.7em;
  right: 0.7em;
  display: flex;
  gap: 0.8em;
}

.mute {
  border: none;
  cursor: pointer;
  border-radius: 50%;
  background:#414141;
  padding: 0.2em;
  color: white;
  height: 2em;
  width: 2em;
}

.fullScreenIcon {
  height: 100%;
  width: 100%;
}

.fullScreen {
  border: none;
  cursor: pointer;
  border-radius: 50%;
  background:#414141;
  padding: 0.2em;
  color: white;
  height: 2em;
  width: 2em;
}

.muteIcon {
  height: 100%;
  width: 100%;
}

  
  @media only screen and (max-width: 600px) {
    .mainDiv img {
      width: 100%;
      aspect-ratio: 4/3;
      object-fit: cover;
    }
    .playpauseIcon {
      height: 7em;
      width: 7em;
    }
  }
  
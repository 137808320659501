/* Bottom Navbar */
.navbar {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-around;
  position: fixed;
  left: 0;
  bottom: 0;
  min-width: 100%;
  padding: 0.7rem 0;
  background-color: var(--color-white);
  box-shadow: 0 -0.3rem 1rem rgba(var(--color-black-rgb), 0.1);
  z-index: 10;
}
.container {
  display: flex;
}
.item {
  padding: 0.8rem;
  border-radius: 0.5rem;
  cursor: pointer;
  height: 4.1rem;
  width: 4.1rem;
  color: var(--color-gray-dark-1);
}

/* Add Items */
.add {
  background-color: var(--color-primary);
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 50%;
  height: 4.1rem;
  width: 4.1rem;
  color: var(--color-white);
}

/* Avatar */
.avatar {
  cursor: pointer;
  max-height: 4.1rem;
  max-width: 4.1rem;
}

/* .notifications {
  padding: 0.8rem;
  border-radius: 0.5rem;
  cursor: pointer;
  height: 4rem;
  width: 4rem;
  color: var(--color-gray-dark-1);
} */
#bottom-navbar {
  position: fixed;
  bottom: 0px;
}

/* @media screen and (min-width: 900px) {
  .navbar {
    display: none !important;
  }
} */

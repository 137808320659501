/* Featured creator header */
.header {
  padding: 1.2rem;
  border-bottom: 2px solid var(--color-gray-light-1);
  height: 4rem;
}

/* Featured Creators Elements */
.element {
  display: flex;
  align-items: center;
  height: 60px;
  padding: 1rem 1.2rem;
  transition: all 0.2s;
}

.element:not(:last-child) {
  border-bottom: 1px solid var(--color-gray-light-1);
}

.profileName {
  cursor: pointer;
}

/* Featured Creators Avatar */
.avatar {
  /* height: 3.5rem;
  width: 3.5rem; */
  margin-right: 1rem;
  cursor: pointer;
  /* margin-top: 0.7rem; */
}

/* Featured Creators Text Wrapper */
.description {
  align-items: center;
  margin-right: auto;
}

.description h4 {
  margin: 0;
}

.description p {
  margin: 0;
}

.follow {
  cursor: pointer;
  height: 2.8rem;
  width: 2.8rem;
  color: var(--color-gray-dark-3);
  padding: 0.5rem;
  align-self: center;
  transition: all 0.2s;
}

/* .follow:hover {
  color: var(--color-gray-dark-1);
  transform: scale(1.1);
  transform-origin: center center;
} */
.fiUserCheck {
  cursor: pointer;
  height: 2.9rem;
  width: 2.9rem;
  color: var(--color-gray-dark-1);
  padding: 0.5rem;
  align-self: center;
  transition: all 0.2s;
}
.popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  width: 30rem;
  max-width: 95vw;
  height: max-content;
  background: #fff;
  border-radius: 1rem;
  box-shadow: 0 0.3rem 0.6rem rgba(var(--color-black-rgb), 0.2);
}

.icon {
  font-size: 1.4rem;
  margin-right: 0.5rem;
}

.header {
  position: relative;
  height: 5rem;
  border-bottom: 2px solid var(--color-gray-light-1);
}

.header h4 {
  text-align: left;
  align-items: middle;
  display: flex;
  align-items: center;
  padding: 1.8rem;
}

.crossiconformodel {
  position: absolute;
  top: 1.4rem;
  right: 1.4rem;
  color: var(--color-gray-medium-2);
  z-index: 10;
  font-size: 2.1rem;
}

.heading {
  color: var(--color-gray-medium-2);
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  font-size: 1.4rem;
  font-weight: 500;
}

.item {
  height: 5rem;
  text-align: left;
  cursor: pointer;
  display: flex;
  vertical-align: middle;
  text-align: left;
  align-items: middle;
  padding: 1.55rem 0 0 1.4rem;
  /* align-items: center; */
  /* transition: all 0.2s !important; */
}

.item:hover {
  background-color: var(--color-gray-light-1);
}

.item:last-child:hover {
  background-color: var(--color-gray-light-1);
  border-radius: 0 0 1rem 1rem;
}

.item:not(:last-child) {
  border-bottom: 1px solid var(--color-gray-light-1);
}

.text {
  font-size: 1.4rem;
  color: var(--color-gray-dark-2);
  font-weight: 500;
}

@media only screen and (max-width: 900px) {
  .popup {
    /* height: 40rem; */
    width: 35rem;
  }
}

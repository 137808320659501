.btnSaveDisabled {
    opacity: 0.5;
    cursor: default !important;
}

.btnSaveDisabled,
.btnSave {
    text-decoration: none;
    padding: 1.15rem 2.75rem;
    font-size: 1.4rem;
    background-color: var(--color-primary);
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-white);
    border-radius: 0.5rem;
    cursor: pointer;
    box-shadow: 0 0.3rem 0.6rem rgba(var(--color-black-rgb), 0.2);
    outline: none;
    border: none;
    font-family: 'Roboto', 'sans-serif';
}

.btnSave:hover {
    background-color: var(--color-primary-light);
}

.btnSave:active {
    transition: 0.55ms;
    transform: scale(0.96);
}

.btnCancel {
    text-decoration: none;
    padding: 1.15rem 2.1rem;
    font-size: 1.4rem;
    background-color: var(--color-gray-medium-2);
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-white);
    border-radius: 0.5rem;
    cursor: pointer;
    box-shadow: 0 0.3rem 0.6rem rgba(var(--color-black-rgb), 0.2);
    margin: 1.5rem 1.5rem 1.5rem 0;
    justify-self: center;
    align-self: center;
    outline: none;
    border: none;
    font-family: 'Roboto', 'sans-serif';
}

.btnCancel:hover {
    background-color: var(--color-gray-medium-1);
}

.btnCancel:active {
    transition: 0.55ms;
    transform: scale(0.96);
}

.modalHeading {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.modalHeading p {
    color: #a3a3a3;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 2rem;
    margin-left: 1.5rem;
}

.wholeModal {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 800px;
    background: white;
    border-radius: 20px;
    outline: none !important;
}

.cropContainer {
    position: relative;
    width: 800px;
    height: 400px;
    background-color: black;
}

.sliderBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    margin-top: 10px;
}

.sliders {
    width: 45%;
}

.buttonBox {
    width: 90%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.typo {
    font-size: 1.6rem;
}

.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
}

.buttonBox button {
    margin-left: 1.5rem;
    margin-bottom: 1.5rem;
}

.crossButton {
    margin-right: 1rem;
    cursor: pointer;
    background: none;
    border: none;
    float: right;
    font-size: 2.2rem;
    color: var(--color-gray-medium-2);
}

@media only screen and (max-width: 800px) {
    .wholeModal {
        width: 94vw;
    }

    .cropContainer {
        width: 94vw;
        height: 47vw;
    }

    .buttonBox {
        justify-content: center;
        grid-column-gap: 20px;
    }

    .buttonBox button {
        margin-left: 0;
    }
}
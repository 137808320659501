.forgetPasswordModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  /* height: 25rem; */
  width: 45rem;
  height: max-content;
  background-color: white;
  border-radius: 1rem;
  text-align: center;
  margin-bottom: 0;
  max-width: 95vw !important;
}

.formForgetPassword {
  margin: 4rem 2rem;
}

.forgetPasswordModalHeader {
  height: 8rem;
}
.forgetPasswordModalOTPContainer {
  margin: 1rem;
  display: flex;
  justify-content: center;
}
.OTPInputField {
  /* opacity: 0.5; */
  margin: 1rem;
  /* padding: 0.6rem; */
  display: flex;
  justify-content: center;
  height: 2.5rem;
  width: 2.5rem;
  border: 1px solid var(--color-gray-medium-3);
  border-radius: 0.2rem;
  background-color: white;
}
.sendOTPButtonEnabled {
  height: 53px;
  width: 120px;
  text-decoration: none;
  padding: 0.95rem;
  font-size: 1.4rem;
  background-color: var(--color-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-white);
  border-radius: 0 0.5rem 0.5rem 0;
  cursor: pointer;
  box-shadow: 0 0.3rem 0.6rem rgba(var(--color-black-rgb), 0.2);
  outline: none;
  border: none;
  font-family: 'Roboto', 'sans-serif';
}
.sendOTPButtonDisabled {
  opacity: 0.5;
  height: 53px;
  width: 120px;
  text-decoration: none;
  padding: 0.95rem;
  font-size: 1.4rem;
  background-color: var(--color-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-white);
  border-radius: 0 0.5rem 0.5rem 0;
  cursor: default;
  box-shadow: 0 0.3rem 0.6rem rgba(var(--color-black-rgb), 0.2);
  outline: none;
  border: none;
  font-family: 'Roboto', 'sans-serif';
}

.resendOTPText {
  font-weight: 400;
  /* color: var(--color-gray-medium-3); */
  font-size: 1.4rem;
  text-align: left;
  margin-left: 3.5rem;
  color: var(--color-primary) !important;
  margin-top: 0.5rem;
}
.forgetPasswordModalBody {
  margin-top: 3rem;
}
.forgetPasswordModalBody h4 {
  font-weight: 400;
  font-style: 1.4rem;
  color: var(--color-gray-medium-3);
}

@media only screen and (max-width: 900px) {
  .OTPInputField {
    border: 1px solid var(--color-gray-dark-2);
  }
  .forgetPasswordModalBody h4 {
    color: var(--color-gray-dark-2);
  }
}

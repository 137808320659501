/* Search form styles */
.form {
  position: relative;
}
@media (max-width: 900px) {
  .form {
    position: relative;
    margin-top: 0;
    margin-bottom: 0;
  }
}

/* Search icon styles */
.icon {
  position: absolute;
  top: 40%;
  left: 1.5rem;
  height: 2.2rem;
  width: 2.2rem;
  color: var(--color-gray-medium-1);
  transform: translateY(-30%);
  transition: all 0.2s;
}

/* Search Input Styles */
.input {
  color: var(--color-gray-dark-2);
  font-size: inherit;
  font-family: inherit;
  padding: 2rem 1rem 2rem 5rem;
  border-radius: 2.5rem;
  border: none;
  width: 100%;
  height: 3rem;
  line-height: 1;
  outline: 1px solid var(--color-gray-light-3);
  transition: all 0.2s;
}
@media (max-width: 900px) {
  .input {
    font-size: 1.8rem;
    height: 1rem;
    width: 100%;
    padding: 2rem 1rem 2rem 5rem;
    margin-bottom: 0;
  }
}

/* Search input placeholder styles */
.input::placeholder {
  color: var(--color-gray-medium-1);
  transition: all 0.2s;
  font-size: 1.6rem;
}

/* Input styles change on input focus */
.input:focus {
  outline: 1px solid var(--color-gray-dark-2);
  background-color: #fff;
}

/* Search icon styles change on input focus */
.input:focus + .icon {
  color: var(--color-gray-dark-2);
}

/* Input placeholder styles change on input focus */
.input:focus::placeholder {
  color: var(--color-gray-dark-2);
}

/* Close Button */
.close {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  height: 3rem;
  width: 3rem;
  color: var(--color-gray-dark-3);
}

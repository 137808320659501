.maxSelectFileErrorModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  width: 30rem;
  background: var(--color-white);
  border-radius: 1rem;
  box-shadow: 0 0.3rem 0.6rem rgba(var(--color-black-rgb), 0.2);
  max-width: 95vw !important;
}
.maxSelectFileErrorModalBox {
  margin: 3rem;
}
.maxSelectFileErrorText {
  color: red;
  font-size: 1.4rem;
  text-align: center;
}

.firstModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  width: 30rem;
  height: 13rem;
  background-color: #fff;
  border-radius: 1rem;
  max-width: 90vw !important;
}

.firstModalMain {
  margin: 2.5rem;
  justify-content: center;
  align-items: center;
}
.firstModalHeading {
  display: flex;
  justify-content: center;
  align-items: center;
}
.firstModalHeading h3 {
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 2rem;
  color: var(--color-gray-medium-2) !important;
}
.firstModalBody {
  display: flex;
  justify-content: center;
  align-items: center;
}

.yesButton,
.noButton {
  text-decoration: none;
  margin-top: 2em;
  padding: 1.15rem 2.5rem;
  font-size: 1.4rem;
  background-color: var(--color-gray-medium-2);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-white);
  border-radius: 0.5rem;
  cursor: pointer;
  box-shadow: 0 0.3rem 0.6rem rgba(var(--color-black-rgb), 0.2);
  margin: 1.5rem;
  justify-self: center;
  align-self: center;
  outline: none;
  border: none;
  font-family: 'Roboto', 'sans-serif';
}

.yesButton:hover {
  background-color: var(--color-gray-medium-1);
}
.yesButton:active {
  transition: 0.55ms;
  transform: scale(0.96);
}

.noButton {
  width: 7.5rem;
  background-color: var(--color-primary);
  color: var(--color-white);
}

.noButton:hover {
  background-color: var(--color-primary-light);
}
.noButton:active {
  transition: 0.55ms;
  transform: scale(0.96);
}

@media only screen and (max-width: 900px) {
  .firstModalHeading h3 {
    color: var(--color-gray-medium-3) !important;
  }
}

.eventCardWrapper {
    position: relative;
    height: 100%;
    width: 100%;
    background-color: var(--color-black);
    overflow: hidden;
    border-radius: 1rem;
}

.eventCardWrapperActive {
    position: relative;
    height: 100%;
    width: 100%;
    background-color: var(--color-black);
    overflow: hidden;
    border-radius: 1rem;
}

.eventCardWrapperActive:active {
    transition: 0.55ms;
    transform: scale(0.96);
}

.imgContainer {
    width: 100%;
    height: 100%;
    background-color: var(--color-black);
}

.imgContainer img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* filter: blur(2px);
    -webkit-filter: blur(2px); */
}

.textContainer {
    padding: 1rem;
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
}

.heroText {
    text-align: center;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    font-size: 1.2rem;
    width: 100%;
    background-color: var(--color-white);
    box-shadow: 0 0.8rem 1.6rem rgba(0, 0, 0, 0.1);
    /* opacity: 0.8; */
    color: var(--color-gray-dark-2);
}

.liveText {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0.35rem 0.8rem;
    background-color: var(--color-white);
    width: max-content;
    border-radius: 5rem;
    /* opacity: 0.8; */
}

.liveText p {
    font-size: 1.2rem;
    /* color: var(--color-primary); */
}

.dot {
    margin-right: 0.5rem;
    content: '';
    width: 0.8rem;
    height: 0.8rem;
    background-color: var(--color-primary);
    border-radius: 100%;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, .3) inset; */
}

@media only screen and (max-width: 600px) {
    .eventCardWrapper {
        border-radius: 1vw;
    }

    .textContainer {
        padding: 1.5vw;
    }

    .heroText {
        border-radius: 0.5vw;
        padding: 0.7vw 1vw;
        font-size: 2.4vw;
    }

    .liveText {
        padding: 0.5vw 1vw;
        border-radius: 6vw;
    }

    .liveText p {
        font-size: 2.4vw;
    }

    .dot {
        width: 1.4vw;
        height: 1.4vw;
    }
}
.fileBox{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 1rem 0.5rem;
}
.elementHeading{
    max-width: 95%;
}
.icon {
    cursor: pointer;
    height: 1.8rem;
    width: 1.8rem;
    color: var(--color-gray-dark-2);
    stroke-width: 2px;
}
body {
    font-size: 1.6rem !important;
}

.carouselItem{
    width: 8rem !important;
    height: 8rem !important;
    display: flex;
    align-items: center;
}
.arrowContainerLeft, .arrowContainerRight{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    border-radius: 3rem;
    height: 3rem;
    width: 3rem;
}

.arrowContainerLeft{
    left: 1px;
}
.arrowContainerRight{
    right: 1px;
}
.carouselBackBtn, .carouselNextBtn{
    outline: 0;
    transition: all .5s;
    border: 0;
    background: transparent;
    opacity: 1;
    cursor: pointer;
    color: white;
}
.carouselBackBtn{
    position: relative;
    left: 1.5px;
}
.thumbnailContainer{
    padding: 1rem 0rem;
}
.thumbnail{
    cursor: pointer;
    height: 6.5rem;
    width: 6.5rem;
    margin:0rem .3rem;
    border-radius: .3rem;
}

.delBtn{
    width: 100%;
    background: none;
    border: none;
    cursor: pointer;
}
.deleteImage{
    background: red;
    color: white;
    float: right;
    width: 1.4rem;
    height: 1.4rem;
    border-radius: 1rem;
    padding: 0.33rem;
    margin-top: -2.3rem;
    margin-right: -0.7rem;
}


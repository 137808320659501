.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  border: none !important;
  outline: none !important;
}

.wholeModal {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: min(50rem, 100vw);
  background: var(--color-white);
  border-radius: 1rem;
  outline: none !important;
}

.modalHeading {
  height: 5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 47rem;
}

.innerModal {
  height: min(62rem, 87dvh);
  width: min(50rem, 100vw);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.insideEditModal, .loader {
  height: min(50rem, 60dvh);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 1.5rem;
  overflow-y: auto;
  overflow-x: hidden;
}

.insideEditModal::-webkit-scrollbar {
  width: .7rem;
}

.categories {
  width: 100%;
  font-size: 1.6rem;
}

/* Track */
.insideEditModal::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.insideEditModal::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.insideEditModal::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.descriptionBox {
  height: 65dvh;
  width: 100%;
  /*height: fit-content;*/
  overflow-x: hidden;
  overflow-y: auto;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
  cursor: text;
  font-size: 1.4rem;
}

.descriptionBox::-webkit-scrollbar {
  width: .7rem;
}

/* Track */
.descriptionBox::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.descriptionBox::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.descriptionBox::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.wordCount {
  display: block;
  width: 100%;
  text-align: right;
  padding: 0 1.5rem;
  font-size: 1.2rem;
}

.dropdownOption {
  font-size: 1.4rem !important;
  text-transform: capitalize;
}

.tag {
  font-size: 1.2rem;
}

.attachBtnBox {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
}


.attachBtnBox button {
  display: block;
  text-decoration: none;
  padding: 1rem;
  font-size: 2rem;
  width: 5rem;
  height: 5rem;
  background-color: var(--color-primary);
  /* display: flex;
  justify-content: center;
  align-items: center; */
  color: var(--color-white);
  border-radius: 2.5rem;
  cursor: pointer;
  box-shadow: 0 0.3rem 0.6rem rgba(var(--color-black-rgb), 0.2);
  outline: none;
  border: none;
  font-family: 'Roboto', 'sans-serif';
}

.attachBtnBox button:hover {
  background-color: var(--color-primary-light);
}

.attachBtnBox button:active {
  transition: 0.55ms;
  transform: scale(0.96);
}

.attachmentsLength {
  position: relative;
  font-size: 0.9rem;
  top: -2.9rem;
  right: -1rem;
  color: var(--color-white);
}

.attachBtn svg {
  position: relative;
  top: 0.5rem;
}

.nextButton {
  width: 95%;
}

.nextButtonContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.btnSaveDisabled {
  opacity: 0.5;
  cursor: default !important;
}

.btnSaveDisabled,
.btnSave {
  text-decoration: none;
  padding: 1.15rem 2.75rem;
  font-size: 1.4rem;
  background-color: var(--color-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-white);
  border-radius: 0.5rem;
  cursor: pointer;
  box-shadow: 0 0.3rem 0.6rem rgba(var(--color-black-rgb), 0.2);
  outline: none;
  border: none;
  font-family: 'Roboto', 'sans-serif';
}

.btnSave:hover {
  background-color: var(--color-primary-light);
}

.btnSave:active {
  transition: 0.55ms;
  transform: scale(0.96);
}

@media only screen and (max-width: 650px) {
  .wholeModal {
    width: 100vw;
    height: 100dvh;
    border-radius: 0;
    margin-top: 0;
    justify-content: space-evenly;
  }

  .modalHeading {
    width: 100vw;
    padding: 2.5rem 2rem;
  }

  .innerModal {
    height: 90dvh;
  }

  .insideEditModal, .loader {
    width: 100vw;
    height: 80dvh;
    justify-content: flex-start;
  }

  .attachBtnBox {
    height: 7dvh;
  }

  .descriptionBox {
    height: 65dvh;
  }

  .nextButtonContainer {
    margin-bottom: 0.5rem;
  }
}

.loader {
  justify-content: center;
}
/* Description Container */
/* Description */
.description2 {
  text-align: justify;
  width: 85%;
}

/* Icon */
.icon {
  color: var(--color-black);
  cursor: pointer !important;
}

.description2text {
  color: var(--color-gray-medium-3);
  font-size: 1.6rem;
  text-align: justify;
}

/* Close description icon */
.closeDescription2Icon {
  color: var(--color-gray-dark-1);
}

@media screen and (max-width: 900px) {
  .description2text {
    color: var(--color-gray-dark-2);
  }
}
@media screen and (max-width: 600px) {
  .description2text {
    font-size: 3.5vw;
  }
}

@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@100;200;300;400;500;600;700&family=IBM+Plex+Sans:wght@100;200;300;400;500;600;700&family=Montserrat:wght@100;200;300;400;500;600;700;900&family=Poppins:wght@300&display=swap');

.landingPage {
  overflow-x: hidden;
}

/*Navbar*/
.navbar {
  position: fixed;
  min-width: 100%;
  top: 0;
  left: 0;
  padding: 0.7rem 2rem;
  background-color: var(--color-white);
  box-shadow: 0 0.3rem 1rem rgba(var(--color-black-rgb), 0.1);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logoContainer {
  display: flex;
  align-items: center;
  margin-right: 4rem;
  cursor: pointer;
  transition: all 0.2s;
}

/* Navbar Logo styles */
.logo {
  height: 4rem;
  margin-right: 0.5rem;
}

.logoText {
  font-size: 3rem;
  font-weight: 500;
  color: var(--color-gray-dark-1);
}

.logoContainer h1 {
  font-size: 4rem;
  font-weight: 500;
  line-height: 5rem;
}

.signInButton {
  text-decoration: none;
  padding: 1.15rem;
  font-size: 1.4rem;
  background-color: var(--color-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-white);
  border-radius: 0.5rem;
  cursor: pointer;
  box-shadow: 0 0.3rem 0.6rem rgba(var(--color-black-rgb), 0.2);
  outline: none;
  border: none;
  font-family: var(--font-sans-serif);
  text-transform: uppercase;
}

.signInButton:hover {
  background-color: var(--color-primary-light);
}

.signInButton:active {
  transition: 0.55ms;
  transform: scale(0.96);
}

/*Banner*/
.bannerSection {
  background-size: auto 40vw;
  background-repeat: no-repeat;
  background-position: bottom right;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  -webkit-transition: background 100ms ease-in-out 100ms;
  -moz-transition: background 100ms ease-in-out 100ms;
  -o-transition: background 100ms ease-in-out 100ms;
  transition: background 100ms ease-in-out 100ms;
}

.bannerText {
  height: 40rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: min(75rem, 90vw);
  margin-left: 8rem;
}

.bannerHeading {
  font-size: 4vw;
  font-family: var(--font-secondary);
  color: var(--color-white);
  font-weight: 600;
}

.bannerTitle {
  color: rgb(255, 255, 255);
  font-family: var(--font-secondary);
  font-weight: 500;
  font-size: 2.4vw;
  margin-bottom: 1rem;
  height: 7.5rem;
  display: flex;
  align-items: center;
}

.btn {
  text-transform: capitalize;
  height: fit-content;
  width: fit-content;
  padding: 2rem 3.5rem;
  font-size: 2rem;
  font-family: var(--font-secondary);
  color: var(--color-primary);
  border-radius: 1rem;
  border: none;
  background: var(--color-white);
  box-shadow: 0 6px 12px 0 rgba(151, 71, 255, 0.31);
  cursor: pointer;
  font-weight: 500;
}

/* Shockwave */
.btnShockwave.isActive {
  -webkit-animation: shockwaveJump 1s ease-out infinite;
  animation: shockwaveJump 1s ease-out infinite;
}

.btnShockwave.isActive:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 50%;
  -webkit-animation: shockwave 1s 0.65s ease-out infinite;
  animation: shockwave 1s 0.65s ease-out infinite;
}

.btnShockwave.isActive:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 50%;
  -webkit-animation: shockwave 1s 0.5s ease-out infinite;
  animation: shockwave 1s 0.5s ease-out infinite;
}

@-webkit-keyframes shockwaveJump {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  40% {
    -webkit-transform: scale(1.08);
    transform: scale(1.08);
  }
  50% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
  }
  55% {
    -webkit-transform: scale(1.02);
    transform: scale(1.02);
  }
  60% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes shockwaveJump {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  40% {
    -webkit-transform: scale(1.08);
    transform: scale(1.08);
  }
  50% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
  }
  55% {
    -webkit-transform: scale(1.02);
    transform: scale(1.02);
  }
  60% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes shockwave {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.15), inset 0 0 1px rgba(0, 0, 0, 0.15);
  }
  95% {
    box-shadow: 0 0 50px transparent, inset 0 0 30px transparent;
  }
  100% {
    -webkit-transform: scale(2.25);
    transform: scale(2.25);
  }
}

@keyframes shockwave {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.15), inset 0 0 1px rgba(0, 0, 0, 0.15);
  }
  95% {
    box-shadow: 0 0 50px transparent, inset 0 0 30px transparent;
  }
  100% {
    -webkit-transform: scale(2.25);
    transform: scale(2.25);
  }
}

.btn--jump.isActive {
  -webkit-animation: 0.4s jump ease infinite alternate;
  animation: 0.4s jump ease infinite alternate;
}

@-webkit-keyframes jump {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  }
  100% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  }
}

@keyframes jump {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  }
  100% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  }
}

/* Sheen */
.sheen {
  position: relative;
  overflow: hidden;
  background-color: lightgray;
  border-color: white;
  color: var(--color-primary);
}

.sheen:after {
  animation: sheen 1s infinite forwards;
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    to bottom,
    transparent,
    rgba(255, 255, 255, 0.5) 50%,
    transparent
  );
  transform: rotateZ(60deg) translate(-1em, 4em);
}

@keyframes sheen {
  100% {
    transform: rotateZ(60deg) translate(0, -4em);
  }
}

.marqueeSection {
  padding: 5rem 0;
  background: rgb(255, 132, 0);
  background: -webkit-linear-gradient(
    left,
    rgba(255, 132, 0, 0.193) 0%,
    rgba(255, 255, 255, 1) 35%,
    rgba(255, 255, 255, 1) 100%
  );
  /* background: radial-gradient(at left,circle, rgba(255,132,0,0.5971638655462185) 0%, rgba(255,255,255,1) 35%, rgba(255,255,255,1) 100%); */
}

.secondHeading {
  font-family: 'IBM Plex Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 3rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #2f0f5d;
}

.secondHeading span {
  font-family: 'IBM Plex Sans', sans-serif;
  font-style: italic;
  font-weight: 400;
}

.marquee {
  padding: 3rem 0;
}

.tagBox {
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 1.4rem;
  text-transform: capitalize;
  padding: 1rem 1.5rem;
  margin: 1rem;
  border-radius: 2.5rem;
  background-color: var(--color-gray-light-2);
  color: var(--color-gray-dark-2);
  z-index: 1;
}

.exploreButton {
  border-radius: 1rem;
  background: var(--color-primary);
  box-shadow: 3px 8px 24px 0 rgba(212, 192, 238, 0.5);
  color: var(--color-white);
  font-size: 2rem;
  font-family: 'IBM Plex Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 1.4rem 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  outline: none;
  border: none;
  cursor: pointer;
}

.exploreButton:hover {
  background-color: var(--color-primary-light);
}

.exploreButton svg {
  font-size: 2.5rem;
}

.exploreButton:active {
  transition: 0.55ms;
  transform: scale(0.96);
}

.footer {
  font-size: 1.4rem;
  padding: 1.6rem;
  background-color: #111518;
  text-align: center;
}
.copyright {
  vertical-align: middle;
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.3rem;
}

.copyright span {
  font-size: 1.6rem;
  margin: 0;
}
.privacyPolicyLink {
  color: #b6193a;
  text-decoration: none;
  display: block;
  margin-top: 5px;
}
@media only screen and (max-width: 900px) {
  .bannerSection {
    height: 100vh;
    background-size: auto 60vh;
    background-position: bottom right;
  }

  .bannerText {
    margin-left: 5rem;
    height: 36rem;
    width: min(75rem, 75vw);
  }

  .bannerTitle {
    font-size: 2.2vw;
  }
}

@media only screen and (max-width: 768px) {
  .bannerSection {
    height: 100vh;
    background-size: auto 50vh;
    background-position: bottom center;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .bannerText {
    align-items: center;
    width: 100vw;
    height: 40rem;
    text-align: center;
    padding: 8rem 4rem 4rem;
    margin: 0;
  }

  .bannerHeading {
    font-size: 4.8vw;
    color: white;
  }

  .bannerTitle {
    color: rgb(255, 255, 255);
    font-size: 2.2vw;
    margin-bottom: 1rem;
    height: 7.5rem;
    display: flex;
    align-items: center;
  }
}

@media only screen and (max-width: 600px) {
  .navbar {
    position: fixed;
    min-width: 100%;
    top: 0;
    left: 0;
    padding: 0.7rem 2rem;
    background-color: var(--color-white);
    box-shadow: 0 0.3rem 1rem rgba(var(--color-black-rgb), 0.1);
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .logoContainer {
    display: flex;
    align-items: center;
    margin-right: 4rem;
    cursor: pointer;
    transition: all 0.2s;
  }

  /* Navbar Logo styles */
  .logo {
    height: 4rem;
    margin-right: 0.5rem;
  }

  .logoContainer h1 {
    font-size: 4rem;
    font-weight: 500;
    line-height: 5rem;
  }

  .bannerSection {
    height: 100vh;
    background-size: auto 50vh;
    background-position: bottom center;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .bannerText {
    align-items: center;
    width: 100vw;
    text-align: center;
    padding: 8rem 2rem 4rem;
    margin: 0;
  }

  .bannerHeading {
    font-size: 6vw;
  }

  .bannerTitle {
    font-size: 4vw;
  }

  .marquee {
    margin: 3rem 0;
    padding: 0;
  }

  .secondHeading {
    font-size: 2.4rem;
  }

  .copyright {
    align-items: flex-start;
  }

  .tagBox {
    margin: 0.8rem;
  }
}

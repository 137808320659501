.divider {
  text-transform: uppercase;
  align-items: center;
  text-align: center;
  display: flex;
  font-size: 1.3rem;
  gap: 5px;
  width: 100%;
  margin: 0 10rem !important;
}

/* .divider::before {
    height: 2px;
    background-color: var(--color);
    width: 2rem;
} */

.divider::after {
  content: '';
  display: block;
  width: 50%;
  border-top: 1px solid var(--color-gray-medium-1);
}
.divider::before {
  content: '';
  display: block;
  width: 50%;
  border-top: 1px solid var(--color-gray-medium-1);
}

.orText {
    display: flex;
    justify-content: center;
    align-items: center;
    /* For 21st soft launch */
    /* visibility: hidden; */
}

.orText p {
    margin: auto;
}
.aspectDropdown{
    font-size: 1.3rem;
    outline: none;
    border: 1px solid var(--color-gray-medium-2);
    color: var(--color-gray-medium-2);
    width: 15rem;
    padding: 0.2rem;
    display: flex;
    float: left;
    text-transform: none;
    font-weight: 300;
}
.aspectDropdown:disabled{
    cursor: not-allowed;
    pointer-events: all;
}
.aspectArrow{
    margin-left: 0.5rem;
}
.aspectArrow polyline{
    stroke: var(--color-gray-medium-2);
}
.popper{
    z-index: 1;
    width: 15rem;
}
.dropdownItem{
    font-size: 1.3rem;
    padding: 0.5rem 1rem;
}

@media only screen and (max-width: 650px) {
    .dropdownItem{
        margin: -1.5rem 0rem;
    }
}
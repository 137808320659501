.tvPage {
  /* min-height: 100vh; */
  padding: 2rem 0;
  /* padding: 2rem 8rem; */
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: var(--color-gray-light-1);
}
@media only screen and (max-width: 900px) {
  .tvPage {
    padding: 2%;
  }
}

@media only screen and (max-width: 600px) {
  .tvPage {
    padding: 2%;
    background-color: #fff !important;
  }
}

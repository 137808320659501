.mainContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 6rem;
}

.name {
    font-weight: 500;
    font-size: 1.4rem;
    /*color: var(--color-gray-dark-2);*/
    color: var(--color-gray-medium-2);
}

@media only screen and (max-width: 900px) {
    .name {
        /*color: var(--color-gray-dark-1);*/
        color: var(--color-gray-medium-3);
    }
}

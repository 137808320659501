.gridMainContainer {
  width: 90vw;
  max-width: 138rem;
}

.settingsMainContainer {
  height: calc(100vh - 56px);
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  background-color: var(--color-gray-light-1);
}
.gridContainer {
  height: 42vh;
  width: 80vw;
  max-width: 46rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.settingsCard {
  height: max-content;
  width: max-content;
  max-width: 42rem;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0.3rem 0.6rem rgba(var(--color-black-rgb), 0.2);
}
.settingsCard a{
  text-decoration:none;
}
.imgDiv {
  height: 28vh;
  width: 27.5vw;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.headingDiv {
  height: 4rem;
}
.headingDiv h1 {
  font-size: 1.6rem;
  font-weight: normal;
  color: var(--color-gray-medium-3);
}

.editPasswordImg {
  width: 60%;
  height: 60%;
}
.helpdeskImg {
  height: 60%;
  width: 60%;
}
.termAndConditionsImg {
  height: 60%;
  width: 60%;
}
.deleteAccountImg {
  height: 60%;
  width: 60%;
}
.editProfileImg {
  height: 60%;
  width: 60%;
}
.creativeInterestImg {
  height: 60%;
  width: 60%;
}

@media only screen and (max-width: 901px) {
  .gridMainContainer {
    width: 90vw;
    height: 72vh;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .gridContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: fit-content;
    width: 40vw;
    margin: 0;
    padding: 0;
    /* margin-top: -3vh; */
  }
  .settingsCard {
    display: flex;
    align-items: center;
    text-align: center;
    margin: 0;
    height: max-content;
    width: max-content;
  }

  .headingDiv {
    height: 3rem;
  }
  .headingDiv h1 {
    color: var(--color-gray-dark-2);
  }
}

@media only screen and (max-width: 600px) {
  .gridMainContainer {
    width: 100%;
  }
  .settingsMainContainer {
    height: calc(100vh - 155px);
    width: 100vw;
  }
  .imgDiv {
    margin-top: 0;
    height: 16vh;
    width: 35vw;
    min-width: 19rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
}

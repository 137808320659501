.goToSignInModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  width: max-content;
  /* width: 25rem; */
  /* height: 34.2rem; */
  background: #fff;
  border-radius: 1rem;
  box-shadow: 0 0.3rem 0.6rem rgba(var(--color-black-rgb), 0.2);
  max-width: 95vw !important;
}
.goToSignInModalBox {
  margin: 3rem;
}
.goToSignInModalText {
  color: var(--color-gray-medium-3);
  font-size: 1.4rem;
  text-align: center;
}
.signInText {
  color: var(--color-primary) !important;
  font-size: 1.4rem;
  text-align: center;
  cursor: pointer;
}

@media only screen and (max-width: 900px) {
  .goToSignInModalText {
    color: var(--color-gray-dark-2);
  }
  .signInText {
    color: var(--color-gray-dark-2);
  }
}

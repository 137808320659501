/* Reaction Container */
.interactions {
    padding: 0 1rem 1rem;
    padding-top: 0.5rem;
}

/* Interaction Container */
.interactionContainer {
    display: flex;
    align-items: center;
    position: relative;
}

/* Interaction counter container */
.counterContainer {
    display: flex;
    align-items: center;
}

/* Interaction Icons */
.attachmentIcon,
.discussIcon,
.shareIcon,
.clockIcon {
    height: 3rem;
    width: 3rem;
    padding: 0.5rem;
    stroke-width: 1.5px;
    color: var(--color-gray-dark-3);
    cursor: pointer;
}

.attachmentIcon,
.discussIcon,
.clockIcon {
    margin-right: 2rem;
}

.attachmentIcon {
    margin-left: auto;
}

.share:active {
    color: var(--color-gray-dark-2);
    transition: 0.55ms ease;
    transform: scale(0.9);
}

/* Interaction Counters */
.reactionCounter,
.attachmentCounter,
.discussCounter,
.shareCounter,
.timeCounter {
    font-size: 1.2rem;
    color: var(--color-gray-medium-2);
    text-align: center;
    width: 3rem;
    margin-bottom: auto;
}

/* Reaction Counter */
.reactionCounter {
    width: 56px;
}

/* Attachment count */
.attachmentCounter {
    margin-left: auto;
    margin-right: 2rem;
    display: flex;
    justify-content: center;
}

/* Discuss Counter */
.discussCounter,
.timeCounter {
    margin-right: 2rem;
}


.termsAndConditionsMainBox {
  padding: 3rem 5rem;
  padding-top: 3rem;
  background-color: white;
  display: block;
  margin: auto;
  width: 60vw;
  height: auto;
  text-align: justify;
  user-select: none;
}

/* Terms of Use */
.termsAndConditionsH1Heading {
  color: var(--color-gray-dark-1) !important;
  font-size: 2.2rem;
}

/* 1. */
.termsAndConditionsH2Heading {
  color: var(--color-gray-dark-1) !important;
  font-size: 1.8rem;
}

/* 1.1 */

.termsAndConditionsH3Heading {
  color: var(--color-gray-dark-1) !important;
  font-size: 1.6rem;
}

.termsAndConditionsH4Heading {
  color: var(--color-gray-dark-1) !important;
  font-size: 1.4rem;
}

/* p */
.termsAndConditionsParagraph {
  color: var(--color-gray-dark-2) !important;
  font-size: 1.4rem;
}
ul {
  padding-left: 2rem;
}
ol {
  padding-left: 2rem;
}
@media screen and (max-width: 900px) {
  .termsAndConditionsMainBox {
    padding: 3rem 1.6rem;
    background-color: white;
    display: block;
    margin: auto;
    width: 100vw;
    height: fit-content;
    border-radius: 0;
    box-shadow: none;
    overflow-x: hidden !important;
  }
  /* Terms of Use */
  .termsAndConditionsH1Heading {
    color: #212529 !important;
    font-size: 2.4rem;
  }

  /* 1. */
  .termsAndConditionsH2Heading {
    /* color: var(--color-gray-dark-1) !important; */
    color: #212529 !important;
    font-size: 2rem;
  }

  /* 1.1 */

  .termsAndConditionsH3Heading {
    /* color: var(--color-gray-dark-1) !important; */
    color: #212529 !important;
    font-size: 1.8rem;
  }

  /* p */
  .termsAndConditionsParagraph {
    color: var(--color-gray-dark-1) !important;
    font-size: 1.6rem;
  }
}
@media print {
  .termsAndConditionsMainBox {
    display: none;
  }
}

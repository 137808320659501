.left {
  width: 280px;
  position: sticky;
  top: 7.6rem
}

@media only screen and (max-width: 900px) {
  .left {
    display: none;
  }
}

.right {
  width: 280px;
  position: sticky;
  top: 7.6rem;
}
.rightDiv {
  padding: 1.2rem;
  height: 20vh;
}

@media only screen and (max-width: 1200px) {
  .right {
    display: none;
  }
}

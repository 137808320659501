.acknowledgementModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  width: 30rem;
  background: var(--color-white);
  border-radius: 1rem;
  box-shadow: 0 0.3rem 0.6rem rgba(var(--color-black-rgb), 0.2);
  max-width: 95vw !important;
}
.acknowledgementModalBox {
  margin: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.checkCircle {
  font-size: 6rem !important;
  color: #14d619;
}

.acknowledgementModalText {
  margin-top: 1rem;
  font-size: 1.6rem;
  text-align: center;
  color: var(--color-gray-medium-3);
}

@media only screen and (max-width: 900px) {
  .acknowledgementModalText {
    color: var(--color-gray-dark-2);
  }
}

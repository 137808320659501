.body {
  /* height: 100%; */
  background-color: var(--color-white);
}
.mainContainer {
  display: flex;
  justify-content: center;
  height: calc(100% - 56px);
}
.leftContainer {
  /* background-color: pink; */
  padding: 0.8rem 2rem;
  /* padding-bottom: 0; */
  /* display: flex;
  flex-direction: column; */
}
.UploadMediaContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed var(--color-gray-medium-3);
  border-radius: 0.5rem;
  width: 70vw;
  /* max-width: 650px; */
  max-width: 768px;
  aspect-ratio: 3/2;
}
.labelHolder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.disabledAddMediaInputDiv {
  display: none;
}

.uploadIcon {
  font-size: 6rem;
  color: var(--color-white);
  fill: var(--color-primary);
}
.uploadIcon:hover {
  fill: var(--color-primary-light);
}
.uploadIcon:active {
  transition: 0.55ms;
  transform: scale(0.96);
}
.descriptionText {
  line-height: 1.7rem;
  font-size: 1.4rem;
  font-weight: 500;
  color: var(--color-gray-medium-3);
}
.previewMedia {
  position: relative;
  width: 70vw;
  max-width: 768px;
  aspect-ratio: 3/2;
  border-radius: 0.5rem;
  margin: 0;
  padding: 0;
}
.previewMedia img {
  position: absolute;
  width: 100%;
  height: 100%;
  aspect-ratio: 3/2;
  object-fit: cover;
  border-radius: 0.5rem;
}
.thumbnailSection {
  display: flex;
  height: 9rem;
  width: 70vw;
  max-width: 768px;
  /* background-color: red; */
}
.addMediaInput {
  width: 7rem;
  height: 7rem;
  aspect-ratio: 1;
  border-radius: 0.5rem;
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-medium-3);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 2rem;
}
.addIcon {
  font-size: 3rem;
  color: var(--color-white);
  fill: var(--color-primary);
}
/* .addIcon:hover {
  fill: var(--color-primary-light);
}
.addIcon:active {
  transition: 0.55ms;
  transform: scale(0.96);
} */
.carouselMedia {
  width: 100vw !important;
  /* background-color: yellow; */
}
.carouselItem {
  width: 7rem !important;
  height: 7rem !important;
  margin-right: 2rem;
  cursor: pointer;
}
.carouselMedia img {
  border-radius: 0.5rem;
  position: absolute;
  object-fit: cover;
  width: 7rem;
  height: 7rem;
}

.crossIcon {
  position: relative;
  top: -0.8rem;
  left: 6.2rem;
  /* font-size: 1.8rem; */
  /* Sir */
  font-size: 1.6rem;
  color: var(--color-primary);
  background-color: var(--color-white);
  border-radius: 5rem;
  cursor: pointer;
}
.crossIcon:hover {
  fill: var(--color-primary-light);
}
.crossIcon:active {
  transition: 0.55ms;
  transform: scale(0.96);
}
/* .crossIcon {
  top: -0.5vw;
  left: 4vw;
  position: absolute;
  font-size: 2.5rem;
  stroke-width: 0;
  color: var(--color-white);
  fill: var(--color-primary);
}
.crossIcon:hover {
  fill: var(--color-primary-light);
}
.crossIcon:active {
  transition: 0.55ms;
  transform: scale(0.96);
} */
.maxSelectFileError {
  text-align: center;
  color: red;
  font-size: 1.4rem;
}
.rightContainer {
  width: 42rem;
  padding: 2rem;
  text-align: center;
  /* background-color: aquamarine; */
}
.disabledProjectTitle {
  height: 5.3rem;
  width: 100%;
  margin: 1rem 0;
  padding: 1.4rem;
  border-radius: 0.5rem;
  background-color: var(--color-white);
  border: 0.1rem solid var(--color-gray-medium-1);
  cursor: not-allowed;
}
.disabledProjectTitle::placeholder {
  opacity: 0.5;
  color: var(--color-gray-medium-1);
  font-size: 1.4rem;
  line-height: 20px !important;
  font-weight: 400 !important;
}
.disabledDescribeYourContentBox {
  height: 23vh;
  width: 100%;
  margin: 1rem 0;
  padding: 1.4rem;
  border-radius: 0.5rem;
  background-color: var(--color-white);
  border: 0.1rem solid var(--color-gray-medium-1);
  cursor: not-allowed;
  margin-bottom: 0.4rem;
}
.disabledDescribeYourContentBox::placeholder {
  opacity: 0.5;
  color: var(--color-gray-medium-1);
  font-size: 1.4rem;
  line-height: 20px !important;
  font-weight: 400 !important;
}
.disabledAddCategoryDiv {
  border: 0.1rem solid var(--color-gray-medium-1);
  border-radius: 0.5rem;
  margin: 1rem auto;
  height: 40vh;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
.addCategoryDiv {
  /* padding: 0 5%; */
  border: 0.1rem solid var(--color-gray-medium-3);
  border-radius: 0.5rem;
  margin: 1rem auto;
  /* sir */
  /* height: 220px; */
  height: 40vh;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
.dropdownOption {
  font-size: 1.4rem !important;
  text-transform: capitalize;
}
.chipContainer {
  text-align: left;
}
.chip {
  font-size: 1.4rem !important;
  margin: 0.1rem !important;
  text-transform: capitalize;
}
.publishButtonDisabled {
  margin-top: 1rem;
  opacity: 0.5;
  text-decoration: none;
  padding: 1.15rem 2.75rem;
  width: 100%;
  font-size: 14px;
  background-color: var(--color-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-white);
  border-radius: 0.5rem;
  cursor: not-allowed !important;
  box-shadow: 0 0.3rem 0.6rem rgba(var(--color-black-rgb), 0.2);
  outline: none;
  border: none;
  font-family: 'Roboto', 'sans-serif';
}
.publishButtonEnabled {
  /* position: relative;
  bottom: 1%; */
  margin-top: 1rem;
  text-decoration: none;
  padding: 1.15rem 2.75rem;
  width: 100%;
  font-size: 14px;
  background-color: var(--color-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-white);
  border-radius: 0.5rem;
  cursor: pointer;
  box-shadow: 0 0.3rem 0.6rem rgba(var(--color-black-rgb), 0.2);
  outline: none;
  border: none;
  font-family: 'Roboto', 'sans-serif';
}

.publishButtonEnabled:hover {
  background-color: var(--color-primary-light);
}
.publishButtonEnabled:active {
  transition: 0.55ms;
  transform: scale(0.96);
}
.helperText {
  margin-left: 1.4rem;
}

.editBannerPencil {
  position: relative;
  margin-top: -5rem;
  margin-left: 45vw;
  background-color: var(--color-gray-medium-3);
  /* box-shadow: 0 0.3rem 0.6rem rgba(var(--color-black-rgb), 0.2); */
  border-radius: 0.5rem;
  height: 4.5rem;
  width: 4.5rem;
  padding: 0.5rem;
  color: var(--color-white);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.editBannerPencilDiv :hover {
  background-color: var(--color-gray-dark-2);
}
.editBannerPencilDiv :active {
  transition: 0.55ms;
  transform: scale(0.96);
}
.cameraIcon {
  cursor: pointer;
  height: 3rem;
  width: 3rem;
  stroke-width: 0;
  fill: var(--color-gray-dark-2);
}
.cameraIcon:hover {
  fill: var(--color-gray-medium-3);
}
.cameraIcon:active {
  transition: 0.55ms;
  transform: scale(0.96);
}
.changeMediaDiv {
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
  background-color: var(--color-gray-light-2);
  margin-top: 1rem;
  border-radius: 0.5rem;
  /* border: 1px solid var(--color-gray-medium-3); */
}
.changeMediaIconDiv label{
  display: flex;
  justify-content: center;
  align-items: center;
}
.changeMediaIconDiv h3 {
  cursor: pointer;
  margin-right: 1rem;
  color: var(--color-gray-dark-1);
  font-size: 1.6rem;
  font-weight: 500;
  /* letter-spacing: 0.1rem; */
}

@media only screen and (max-height: 650px) and (min-width: 900px) {
  .UploadMediaContainer {
    max-width: 650px;
  }
  .previewMedia {
    max-width: 650px;
  }
  .thumbnailSection {
    max-width: 650px;
  }
  .addCategoryDiv {
    height: 220px;
  }
  .disabledAddCategoryDiv {
    height: 220px;
  }
}

@media only screen and (max-width: 900px) {
  .mainContainer {
    display: block;
  }
  .leftContainer {
    /* background-color: pink; */
    padding: 1rem 2rem 0 2rem;
  }
  .UploadMediaContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed var(--color-gray-dark-2);
    border-radius: 0.5rem;
    min-width: 100%;
    /* max-width: 768px; */
    aspect-ratio: 3/2;
  }
  .labelHolder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .disabledAddMediaInputDiv {
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: not-allowed; */
    display: none;
  }

  .uploadIcon {
    font-size: 6rem;
    color: var(--color-white);
    fill: var(--color-primary);
  }
  .uploadIcon:hover {
    fill: var(--color-primary-light);
  }
  .uploadIcon:active {
    transition: 0.55ms;
    transform: scale(0.96);
  }
  .descriptionText {
    color: var(--color-gray-dark-2);
  }
  .previewMedia {
    position: relative;
    min-width: 100%;
    /* max-width: 768px; */
    aspect-ratio: 3/2;
  }
  .previewMedia img {
    /* position: absolute; */
    width: 100%;
    /* height: 100%; */
    /* aspect-ratio: 3/2; */
    /* object-fit: fill; */
    /* object-fit: contain; */
    object-fit: cover;
    border-radius: 0.5rem;
  }
  .thumbnailSection {
    display: flex;
    height: 10rem;
    min-width: 100%;
    /* max-width: 768px; */
    /* background-color: red; */
  }
  .addMediaInput {
    width: 7rem;
    height: 7rem;
    aspect-ratio: 1;
    border-radius: 0.5rem;
    background-color: var(--color-white);
    border: 1px solid var(--color-gray-dark-2);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .addIcon {
    font-size: 2.5rem;
    color: var(--color-white);
    fill: var(--color-primary);
  }
  /* .addIcon:hover {
  fill: var(--color-primary-light);
}
.addIcon:active {
  transition: 0.55ms;
  transform: scale(0.96);
} */

  /* .crossIcon {
    position: relative;
    top: -1.2rem;
    left: 6.2rem;
    font-size: 1.8rem;
    color: var(--color-primary);
    background-color:var(--color-white);
    border-radius: 5rem;
    cursor: pointer;
  }
  .crossIcon:hover {
    fill: var(--color-primary-light);
  }
  .crossIcon:active {
    transition: 0.55ms;
    transform: scale(0.96);
  } */
  /* .crossIcon {
  top: -0.5vw;
  left: 4vw;
  position: absolute;
  font-size: 2.5rem;
  stroke-width: 0;
  color: var(--color-white);
  fill: var(--color-primary);
}
.crossIcon:hover {
  fill: var(--color-primary-light);
}
.crossIcon:active {
  transition: 0.55ms;
  transform: scale(0.96);
} */
  .maxSelectFileError {
    text-align: center;
    color: red;
    font-size: 1.4rem;
  }
  .rightContainer {
    width: 100%;
    padding: 1rem 2rem;
    text-align: center;
    /* background-color: aquamarine; */
  }
  .addCategoryDiv {
    padding: 0;
    padding-bottom: 1rem;
    /* padding: 0 3%; */
    border: 0.1rem solid var(--color-gray-dark-1);
    border-radius: 0.5rem;
    margin: 1rem auto;
    min-height: 10rem;
    height: max-content;
    /* height: 40vh; */
    width: 100%;
    overflow-x: hidden;
    /* overflow-y: auto; */
  }
  .dropdownOption {
    font-size: 1.4rem !important;
    text-transform: capitalize;
  }
  .chipContainer {
    text-align: left;
    margin-left: 1rem;
  }
  .chip {
    font-size: 1.4rem !important;
    margin: 0.2rem !important;
    text-transform: capitalize;
  }
  .AddMinimumCategoriesWarningText {
    text-align: left;
    color: red;
    margin-top: -1rem;
    margin-left: 1.4rem;
  }
  .publishButtonDisabled {
    /* position: relative;
    bottom: 1%; */
    margin-top: 1rem;
    opacity: 0.5;
    text-decoration: none;
    padding: 1.15rem 2.75rem;
    width: 100%;
    font-size: 14px;
    background-color: var(--color-primary);
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-white);
    border-radius: 0.5rem;
    cursor: default !important;
    box-shadow: 0 0.3rem 0.6rem rgba(var(--color-black-rgb), 0.2);
    outline: none;
    border: none;
    font-family: 'Roboto', 'sans-serif';
  }
  .publishButtonEnabled {
    /* position: relative;
    bottom: 1%; */
    margin-top: 1rem;
    text-decoration: none;
    padding: 1.15rem 2.75rem;
    width: 100%;
    font-size: 14px;
    background-color: var(--color-primary);
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-white);
    border-radius: 0.5rem;
    cursor: pointer;
    box-shadow: 0 0.3rem 0.6rem rgba(var(--color-black-rgb), 0.2);
    outline: none;
    border: none;
    font-family: 'Roboto', 'sans-serif';
  }

  .publishButtonEnabled:hover {
    background-color: var(--color-primary-light);
  }
  .publishButtonEnabled:active {
    transition: 0.55ms;
    transform: scale(0.96);
  }
  .editBannerPencil {
    position: relative;
    margin-top: -5.5vw;
    margin-left: 85vw;
    background-color: var(--color-gray-medium-3);
    border-radius: 0.5vw;
    height: 4.5vw;
    width: 4.5vw;
    padding: 0.5vw;
    color: var(--color-white);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .editBannerPencilDiv :hover {
    background-color: var(--color-gray-dark-2);
  }
  .editBannerPencilDiv :active {
    transition: 0.55ms;
    transform: scale(0.96);
  }
  .featherUser2 {
    height: 3vw;
    width: 3vw;
    stroke-width: 0;
  }
}

@media only screen and (max-width: 600px) {
  .emptyDiv {
    height: 50px;
  }
}

.AccountNotRegisteredModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  width: 30rem;
  /* height: 34.2rem; */
  background: #fff;
  border-radius: 1rem;
  box-shadow: 0 0.3rem 0.6rem rgba(var(--color-black-rgb), 0.2);
  max-width: 95vw !important;
}
.AccountNotRegisteredModalBox {
  margin: 3rem;
}
.AccountNotRegisteredText {
  color: var(--color-gray-medium-3);
  font-size: 1.4rem;
  text-align: center;
}
.signUPText {
  color: var(--color-gray-medium-3);
  font-size: 1.4rem;
  text-align: center;
}

@media only screen and (max-width: 900px) {
  .AccountNotRegisteredText {
    color: var(--color-gray-dark-2);
  }
  .signUPText {
    color: var(--color-gray-dark-2);
  }
}

/* Left Side of Navbar */
.left {
  display: flex;
  align-items: center;
  /* max-height: 4.8rem; */
}

.logoContainer {
  display: flex;
  align-items: center;
  margin-right: 4rem;
  cursor: pointer;
  transition: all 0.2s;
}

/* Navbar Logo styles */
.logo {
  height: 4rem;
  margin-right: 0.5rem;
}
/* @media (max-width: 900px) {
  .logo {
    max-height: 4rem;
  }
} */
.logoText {
  font-size: 3rem;
  /* font-size: 3.4rem; */
  font-weight: 500;
  color: var(--color-gray-dark-1);
  /* margin-bottom: 0; */
}
/* @media (max-width: 900px) {
  .logoText {
    font-size: 2.9rem;
  }
} */

.toggleicon {
  background-color: var(--color-primary);
  position: relative;
  font-size: 32px;
  color: white;
  width: 110%;
  padding: 5px;
  margin-top: -5%;
  margin-left: -2%;
  border-radius: 100px;
}

.toggleclose {
  font-size: 32px;
  color: blue;
  width: 110%;
  padding: 5px;
  margin-top: -5%;
  margin-left: -20%;
  border-radius: 100px;
  background-color: #ccc;
}

.searchcreator {
  /* color: white; */
  margin-left: -30px;
  position: relative;
  /* background-color: var(--color-gray-medium-1); */
  /* border-radius: 100px; */
  /* width: 5%; */
  /* box-shadow: 0 0.1rem 0.1rem rgba(var(--color-black-rgb), 0.2); */
  /* font-size: 20px; */
  padding-left: 1px;
  padding-top: -5px;
  background-position: center;
  margin-top: 4px;
}

.creatoricon {
  font-size: 20px;
  padding-left: 2px;
  margin-top: -20%;
  margin-left: 10%;
}

@media (max-width: 768px) {
  .logoContainer {
    margin-right: 2rem;
  }
}
/*@media (min-width: 1201px) {
  .searchcreator {
    margin-left: -70px;
  }
} */

/* ? Variables */
:root {
  /* Color Variables */

  --color-primary: #d11414;
  --color-primary-light: #e90000;
  --color-primary-dark: #be2727;

  --color-primary-test-1: #bf1e2d; /* New logo color*/
  --color-primary-test-2: #d33900; /* New logo color*/
  --color-primary-new: #b72a3b; /* Jagjot */

  --color-logo: #78255a;

  --color-white: #fff;
  --color-white-rgb: 255, 255, 255;
  --color-black: #000;
  --color-black-rgb: 00, 00, 00;

  --color-gray-light-1: #eee;
  --color-gray-light-2: #ddd;
  --color-gray-light-3: #cbcdda;

  --color-gray-medium-1: #b9b9b9;
  --color-gray-medium-2: #a3a3a3;
  --color-gray-medium-3: #6e6e6e;

  --color-gray-dark-1: #333;
  --color-gray-dark-2: #414141;
  --color-gray-dark-3: #888;

  --color: rgb(38, 0, 255);

  /* Font Variables */
  --font-sans-serif: 'Roboto', sans-serif; /* 400, 500 */
  --font-serif: 'Roboto Serif', serif; /* 600 */

  --font-secondary: 'Poppins', sans-serif;
}

/* ? Typography Classes */

/* Component(like Featured creators and dropdown) Heading */
.component-heading {
  color: var(--color-gray-medium-2);
  font-size: 1.4rem;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.1rem;
}

/* Element(like a notification from notification dropdown) Heading */
.element-heading {
  font-weight: 500;
  font-size: 1.4rem;
  color: var(--color-gray-dark-2);
}

/* Element Description */
.element-description {
  font-size: 1.2rem;
  color: var(--color-gray-medium-2);
}

/* Element color primary red  */
.element-color {
  color: var(--color-primary);
}

.description-text {
  color: var(--color-gray-medium-3);
  font-size: 1.4rem;
}

/* ? Styles */
/* margin and padding reset */
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%; /* Converting 1rem = 10px for ease in development */
}

img {
  pointer-events: none;
}

@media only screen and (max-width: 37.5em) {
  html {
    font-size: 50%;
  }
}

@media only screen and (max-width: 75em) {
  html {
    font-size: 56.25%;
  }
}

@media only screen and (min-width: 112.5em) {
  html {
    font-size: 75%;
  }
}

body {
  font-size: 1.6rem;
  font-weight: 400;
  background-color: var(--color-gray-light-1);
  font-family: var(--font-sans-serif);
  line-height: 1.4;
}

* {
  font-family: var(--font-sans-serif);
  -webkit-tap-highlight-color: transparent;
}

.MuiFormHelperText-root {
  font-size: 1.25rem;
  color: #d32f2f;
}

.cross-button {
  margin-top: 1rem;
  margin-right: 1rem;
  cursor: pointer;
  background: none;
  border: none;
  float: right;
  font-size: 2.2rem;
  color: var(--color-gray-medium-2);
}

@media only screen and (max-width: 900px) {
  .component-heading {
    color: var(--color-gray-medium-3);
  }

  .element-heading {
    color: var(--color-gray-dark-1);
  }

  .element-description {
    color: var(--color-gray-medium-3);
  }

  .description-text {
    color: var(--color-gray-dark-2);
  }

  .cross-button {
    color: var(--color-gray-medium-3);
  }
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  border: none !important;
  outline: none !important;
}

.wholeModal {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: min(50rem, 100vw);
  background: white;
  border-radius: 1rem;
  box-shadow: 0 0.3rem 0.6rem rgba(var(--color-black-rgb), 0.2);
  outline: none !important;
}

.modalHeading {
  height: 5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 47rem;
}

.insideUploadModal {
  height: min(62rem, 87dvh);
  width: min(50rem, 100vw);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
}

.UploadMediaContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  border: .1rem dashed var(--color-gray-medium-3);
  border-radius: 0.5rem;
  width: 100%;
  aspect-ratio: 2/1;
}

.UploadMediaContainer button {
  border: none;
  background: none;
}

.uploadIconDisabled {
  cursor: not-allowed !important;
}

.uploadIcon, .uploadIconDisabled {
  cursor: pointer;
  font-size: 6rem;
  color: var(--color-white);
  fill: var(--color-primary);
}

.uploadIcon:hover {
  fill: var(--color-primary-light);
}

.uploadIcon:active {
  transition: 0.55ms;
  transform: scale(0.96);
}

.descriptionText {
  cursor: pointer;
  line-height: 1.7rem;
  font-size: 1.4rem;
  font-weight: 500;
  color: var(--color-gray-medium-3);
}

.innerModal {
  height: min(62rem, 87dvh);
  width: min(50rem, 100vw);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.insideEditModal, .loader {
  height: min(58rem, 87dvh);
  width: min(50rem, 100vw);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 1.5rem;
  /* padding-top: 0; */
  overflow-y: auto;
  overflow-x: hidden;
}

.insideEditModal::-webkit-scrollbar {
  width: .7rem;
}

/* Track */
.insideEditModal::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.insideEditModal::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.insideEditModal::-webkit-scrollbar-thumb:hover {
  background: #555;
}


/*.crossButton {*/
/*    margin-top: 0;*/
/*    cursor: pointer;*/
/*    background: none;*/
/*    border: none;*/
/*    float: right;*/
/*    font-size: 2.2rem;*/
/*    color: black;    */
/*}*/


/*.descriptionText {*/
/*    color: var(--color-gray-medium-2);*/
/*    font-weight: 500;*/
/*}*/


/*.uploadIcon, .uploadIconDisabled {*/
/*    cursor: pointer;*/
/*    font-size: 6rem;*/
/*    color: var(--color-white);*/
/*    fill: var(--color-primary);*/
/*}*/
/*.uploadIcon:hover {*/
/*    fill: var(--color-primary-light);   */
/*}*/
/*.uploadIcon:active {*/
/*    transition: 0.55ms;*/
/*    transform: scale(0.96);*/
/*}*/


.aspectBox {
  width: 100%;
  margin-bottom: 0.7rem;
}


.allThumbnail {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1rem;
  height: 8rem;
}

.uploadBtn, .uploadBtnDisabled {
  width: 6.5rem;
  height: 6.5rem;
  border-radius: .3rem;
  margin-right: .3rem;
  cursor: pointer;
  border: .1rem solid gray;
  background: none;
  padding: 1rem 0;
}

.uploadBtnDisabled {
  opacity: 0.5;
  cursor: not-allowed !important;
}

.uploadBtn:hover {
  background-color: rgb(232, 230, 230);
}

.uploadBtn:active {
  transition: 0.55ms;
  transform: scale(0.96);
}

.uploadBtnSvg, .uploadBtnSvgDisabled {
  cursor: pointer;
  font-size: 2.5rem;
  color: var(--color-white);
  fill: var(--color-primary);
}

.uploadBtnSvgDisabled {
  cursor: not-allowed;
}

.uploadBtnLoader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel {
  width: 38rem;
  margin-left: 1rem;
}

.nextButtonContainer {
  width: 94%;
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.nextButton {
  width: 95%;
}

.btnSaveDisabled {
  opacity: 0.5;
  cursor: default !important;
}

.btnSaveDisabled,
.btnSave {
  width: 100%;
  text-decoration: none;
  padding: 1.15rem 2.75rem;
  font-size: 1.4rem;
  background-color: var(--color-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-white);
  border-radius: 0.5rem;
  cursor: pointer;
  box-shadow: 0 0.3rem 0.6rem rgba(var(--color-black-rgb), 0.2);
  outline: none;
  border: none;
  font-family: 'Roboto', 'sans-serif';
}

.btnSave:hover {
  background-color: var(--color-primary-light);
}

.btnSave:active {
  transition: 0.55ms;
  transform: scale(0.96);
}


@media only screen and (max-width: 650px) {
  .wholeModal {
    width: 100vw;
    height: 100dvh;
    border-radius: 0;
    margin-top: 0;
    justify-content: space-evenly;
  }

  .modalHeading {
    width: 100vw;
    height: 5dvh;
    padding: 2.5rem 2rem;
  }

  .insideUploadModal, .loader {
    width: 100vw;
    height: 90dvh;
    justify-content: flex-start;
  }

  .UploadMediaContainer {
    width: 90vw;
  }

  .insideEditModal, .loader {
    width: 100vw;
    height: 85dvh;
    justify-content: flex-start;
  }

  .innerModal {
    height: 90dvh;
  }


  .uploadBtn {
    width: 7rem;
    height: 7rem;
  }

  .carousel {
    width: 72vw;
    margin-left: 1.2rem;
  }

  .nextButtonContainer {
    margin-bottom: 0.5rem;
  }
}

.loader {
  justify-content: center;
}
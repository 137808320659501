.screenContainer {
    height: 100%;
    background-color: var(--color-gray-light-1);
}

.mainDivContainer {
    height: 540px;
    max-height: max-content;
    width: 900px;
    max-width: 96vw;
    max-height: 96vh;
    background-color: var(--color-white);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    box-shadow: 0 0.3rem 0.6rem rgba(var(--color-black-rgb), 0.5);
    display: flex;
}

.divLeft,
.divRight {
    width: 50%;
    height: auto;
    border-radius: 10px 0 0 10px;
    position: relative;
}

.divLeft {
    padding: 3.1rem;
    background-image: linear-gradient(to top right, #6e6e6e, #888);
}

.divRight {
    background-color: none;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.logoImg {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    padding: 0 2rem;
}

.logoDiv {
    display: flex;
    align-items: center;
}

.logo {
    width: 4rem;
    height: 4rem;
    margin-right: 1rem;
}

.logoHeading {
    font-size: 3.4rem;
    font-weight: 500;
    color: var(--color-white);
}

.descriptionDiv {
    margin-top: 16.2rem;
}

.descriptionHeading {
    font-size: 4rem;
    font-weight: 500;
    color: var(--color-white);
    line-height: 5rem;
}

.descriptionText {
    margin-top: 5vh;
    font-size: 15px;
    color: var(--color-white);
    font-weight: 400;
}

.signUpAsk {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: max-content;
    color: var(--color-gray-medium-3);
    padding: 0 auto;
}

.signUpAsk p {
    margin-bottom: 0;
}

.signUpParagraph {
    margin-left: 0.6rem;
    color: var(--color-primary);
    cursor: pointer;
}

.textCheck {
    width: 100%;
    margin: 1rem 0;
}

/* Right Container  */
.rightContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2em;
}

.rightContainerDesc {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
}

.formContainer {
    display: flex;
    flex-direction: column;
    gap: 1em;
    margin-top: -1rem;
}

/* SignIn Forms Fields Container  */
.formFieldsContainer {
    display: flex;
    flex-direction: column;
    max-height: max-content;
}

.formFields {
    display: flex;
    flex-direction: column;
    gap: 4rem;
}

.fieldsOnly {
    margin: 0 5rem;
}

.formFieldsContainer > span {
    align-self: center;
    color: var(--color-gray-medium-3);
}

.formFieldsContainer > span > a {
    text-decoration: none;
    color: var(--color-primary);
}

.textFieldContainer {
    font-size: 12px;
}

/* SignIn heading */
.heading {
    font-size: 3.5rem;
    color: var(--color-gray-dark-1);
    font-weight: 700;
    margin: 0 auto;
}

.orText {
    display: flex;
    justify-content: center;
    align-items: center;
    /* For 21st soft launch */
    /* visibility: hidden; */
}

.orText p {
    margin: auto;
}

/* SignIn Buttons */
.buttonDisabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.button,
.buttonDisabled {
    text-decoration: none;
    margin: 2rem auto;
    margin-bottom: 0;
    padding: 1.15rem 2.3rem;
    font-size: 1.4rem;
    background-color: var(--color-primary);
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-white);
    border-radius: 0.5rem;
    cursor: pointer;
    box-shadow: 0 0.3rem 0.6rem rgba(var(--color-black-rgb), 0.2);
    justify-self: center;
    align-self: center;
    outline: none;
    border: none;
    font-family: 'Roboto', 'sans-serif';
}

.button:hover {
    background-color: var(--color-primary-light);
}

.button:active {
    transition: 0.55ms;
    transform: scale(0.96);
}

.signInFormErrorMsg {
    min-height: 2rem;
    display: flex;
    padding: 0.25em 0.25em;
    width: 100%;
}

/* Forgot Password and Remember Me container */
.moreFeatures {
    display: flex;
    justify-content: space-between;
    max-height: max-content;
}

.moreFeatures > p {
    font-size: 14px;
    opacity: 0.8;
    height: max-content;
}

.moreFeatures > button {
    background-color: var(--color-white);
    outline: 0;
    border: 0;
    cursor: pointer;
    font-size: 14px;
    margin: 0;
}

.checkbox {
    cursor: pointer;
    padding: 0;
    display: flex;
    gap: 0.5em;
    align-items: center;
    margin-left: 5rem;
    height: max-content;
    visibility: hidden;
}

.checkbox input {
    cursor: pointer;
    width: 14px;
    height: 14px;
}

input[type='checkbox'] {
    accent-color: var(--color-primary);
}

.checkbox label {
    cursor: pointer;
    font-size: 14px;
    color: var(--color-gray-medium-3);
    display: inline;
    padding: 0;
    margin: 0 auto;
}

/* Social Login Elements */

span iframe {
    opacity: 0;
}

.socialLoginContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 0.5em;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    /* height: 3.5rem; */
}

.socialLoginGoogle {
    display: flex;
    /* width: 175px; */
    width: 100%;
    justify-content: center;
    /* height: 32px; */
}


.socialLoginFacebook {
    display: flex;
    width: 175px;
    justify-content: center;
    align-items: center;
    background-color: #4285f4;
    height: 32px;
    border-radius: 4px;
    background: url(/src/Assets/Images/fb_signIn.jpeg);
    background-size: 175px 32px;
    color: transparent;
    &:hover {
        opacity: 0.9;
    }
}




.btnText {
    color: var(--color-white);
    font-size: 1.4rem;
    margin-right: 1rem;
}

/* Social Login and Form Divider */

.signUpLink {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.signUpLink p {
    font-size: 14px;
    opacity: 0.8;
}

.signUpLink span {
    font-size: 14px;
    opacity: 0.8;
}

.facebookBtn {
    background: transparent;
    border: none;
}

.forgotPasswordText {
    margin: 0;
    margin-right: 5rem;
    color: var(--color-primary);
    height: max-content;
}

@media only screen and (max-width: 1200px) {
    .divLeft {
        padding: 3.8rem;
    }

    .descriptionDiv {
        margin-top: 18.7rem;
    }
}

@media (min-width: 475px) and (max-width: 768px) {
    .mainDivContainer {
        width: 425px;
        max-width: 96vw;
        padding: 3.5% 0;
        transition: all 0.2s;
    }

    .divLeft {
        display: none;
    }

    .divRight {
        width: 100%;
    }
}

@media (min-width: 345px) and (max-width: 475px) {
    body {
        background-color: var(--color-white);
    }

    .screenContainer {
        background-color: var(--color-white);
    }

    .mainDivContainer {
        background-color: var(--color-white);
        width: 100vw;
        border-radius: 0;
        box-shadow: none;
    }

    .divLeft {
        display: none;
    }

    .divRight {
        width: 100%;
        padding: 0;
        margin: 0;
    }
}

@media screen and (max-width: 345px) {
    body {
        background-color: var(--color-white);
    }

    .screenContainer {
        background-color: var(--color-white);
    }

    .mainDivContainer {
        background-color: var(--color-white);
        width: 100vw;
        border-radius: 0;
        box-shadow: none;
    }

    .divLeft {
        display: none;
    }

    .divRight {
        width: 100%;
        padding: 0;
        margin: 0;
    }

    .fieldsOnly {
        margin: 1rem;
    }

    .checkbox {
        margin-left: 1rem;
    }

    .forgotPasswordText {
        margin-right: 1rem;
    }
}
.UserProfile {
  background-color: var(--color-white);
  display: block;
  margin: auto;
  width: 90rem;
  height: auto;
  border-radius: 1rem;
  box-shadow: 0 0.3rem 0.6rem rgba(var(--color-black-rgb), 0.2);
}

.vl {
  display: inline;
  border-left: 0.2rem solid var(--color-gray-light-3);
  height: 6rem;
  margin-top: 3rem;
}

.userBanner img {
  width: 90rem;
  aspect-ratio: 3;
  overflow: hidden;
  object-fit: cover;
}

.shareIconContainer {
  background-color: var(--color-gray-medium-3);
  border-radius: 100%;
  height: 4.5rem;
  width: 4.5rem;
  padding: 0.5rem;
  color: var(--color-white);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.shareIcon {
  height: 2.4rem;
  width: 2.4rem;
  margin-right: 0.3rem;
}

.editBannerPencilDiv {
  display: flex;
  width: fit-content;
  position: relative;
  margin-top: -3rem;
  margin-left: 76rem;
  margin-bottom: -7.7rem;
}

.editBannerPencil {
  margin-left: 2rem;
  position: relative;
  background-color: var(--color-gray-medium-3);
  border-radius: 100%;
  height: 4.5rem;
  width: 4.5rem;
  padding: 0.5rem;
  color: var(--color-white);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.editBannerPencilDiv :hover {
  background-color: var(--color-gray-dark-2);
}

.editBannerPencilDiv :active {
  transition: 0.55ms;
  transform: scale(0.96);
}

.featherUser {
  height: 2.4rem;
  width: 2.4rem;
  margin-left: 0.3rem;
}

.featherUserCheck {
  height: 2.4rem;
  width: 2.4rem;
  margin-left: 0.3rem;
}

.featherUser2 {
  height: 3rem;
  width: 3rem;
  stroke-width: 0;
}

.fansUserPlusIcon {
  /* visibility: hidden; */
  position: relative;
  top: -1.2rem;
  left: 82.5rem;
  background-color: var(--color-gray-medium-3);
  box-shadow: 0 0.3rem 0.6rem rgba(var(--color-black-rgb), 0.2);
  border-radius: 100%;
  height: 4.5rem;
  width: 4.5rem;
  padding: 0.5rem;
  color: var(--color-white);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.favouritesUserCheckIcon {
  position: relative;
  top: 3.3rem;
  left: 82.5rem;
  background-color: var(--color-gray-dark-2);
  box-shadow: 0 0.3rem 0.6rem rgba(var(--color-black-rgb), 0.2);
  border-radius: 100%;
  height: 4.5rem;
  width: 4.5rem;
  padding: 0.5rem;
  color: var(--color-white);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all;
}

.userAvtar img {
  height: 18rem;
  width: 18rem;
  margin-top: -12.4rem;
  margin-left: 3rem;
  padding: 0.5rem;
  border: 0.3rem solid var(--color-white);
  border-radius: 100%;
  background: var(--color-white);
  object-fit: cover;
  box-shadow: 0 0.3rem 0.6rem rgba(var(--color-black-rgb), 0.2);
}

.userNameText {
  margin-left: 22.5rem;
  margin-top: -10rem;
  width: 55%;
}

.userNameText h1 {
  font-size: 3rem;
  color: var(--color-gray-dark-1);
  font-weight: 800;
  width: 30ch;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.userNameText p {
  font-size: 1.8rem;
  color: var(--color-gray-medium-2);
  font-weight: 400;
}

.userScore {
  width: 90rem;
  margin: 3rem auto;
}

.post {
  margin-top: 1%;
}

.postSection {
  border-radius: 1rem;
  background-color: var(--color-white);
  width: 90rem;
  box-shadow: 0 0.3rem 0.6rem rgba(var(--color-black-rgb), 0.2);
  margin: 1% auto auto;
}

.postHeadings {
  display: block;
}

.post1 .images {
  margin-top: 13%;
}

.modalImages {
  position: relative;
  cursor: pointer;
  border-radius: 0.5rem;
  width: 27.05rem;
  aspect-ratio: 3/2;
  margin: 0.7rem;
  object-fit: cover;
}

.myContentBar {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 3rem;
  margin-top: 1rem;
  padding: 0 2rem 0 0;
}

.postHeader {
  display: flex;
  margin: 1rem 0 0.8rem;
  padding-top: 0.5rem;
}

.vertical1 {
  margin-left: 33%;
  margin-top: -9%;
}

.vertical {
  margin-left: 70%;
  margin-top: -20%;
}

.content {
  display: block;
  margin: 0 3rem;
  justify-content: space-between;
  padding-bottom: 1rem;
}

.postBody {
  padding: 0 2.3rem;
}

.images {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}

.userScore {
  justify-content: center;
  align-items: center;
}

.Score1 {
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 4rem;
  display: inline-flex;
  align-items: center;
}

.Score2 {
  cursor: pointer;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 3rem;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
}

.srijconscore {
  margin: auto auto auto 0.8rem;
  font-weight: 800;
  font-size: 2rem;
  color: var(--color-gray-dark-1);
}

.fansScore,
.favouritesScore {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  font-weight: 800;
  font-size: 2rem;
  color: var(--color-gray-dark-1);
}

.Score1 img {
  height: 3rem;
  width: 3rem;
}

.jagjot {
  display: flex;
  align-items: center;
}

.jagjot2 {
  display: flex;
  align-items: center;
}

.srijcontitle {
  position: absolute;
  margin: 7rem auto auto 0;
  /* width: max-content; */
  font-size: 1.6rem;
  color: var(--color-gray-medium-2);
}

.fansTitle,
.favouritesTitle {
  position: absolute;
  margin: 7rem auto auto;
  font-size: 1.6rem;
  color: var(--color-gray-medium-3);
  font-weight: 550;
}

.srijconContainer {
  display: flex;
  margin-left: 3rem;
  align-items: center;
}

.multipleIcon {
  position: absolute;
  top: 0.3rem;
  right: 0.3rem;
  font-size: 2.4rem;
  color: var(--color-white);
  /*box-shadow: 0 0.8rem 1.6rem rgba(0, 0, 0, 0.1)*/
}
.videoIcon {
  position: absolute;
  top: 0.3rem;
  right: 0.3rem;
  font-size: 2.4rem;
  color: var(--color-white);
  /*box-shadow: 0 0.8rem 1.6rem rgba(0, 0, 0, 0.1)*/
}

.marks {
  display: flex;
  align-items: center;
}

.marks2 {
  display: flex;
  align-items: center;
}

.tvCardModalContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50rem;
  background: var(--color-white);
  border-radius: 1rem;
  box-shadow: 0 0.3rem 0.6rem rgba(var(--color-black-rgb), 0.2);
  max-width: 95vw;
  transition: all 0.2s;
}

.scoreContainer {
  display: flex;
  margin: 5.7rem 3rem 3rem;
}

.srijconScoreContainer {
  flex-direction: column;
  align-items: center;
  width: fit-content;
  margin-right: 3rem;
  display: none;
}

.ecoImpactContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  margin-right: 3rem;
}

.socialImpactContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  margin-right: 3rem;
}

.vlContainer {
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin-right: 3rem;
}

.fansContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  margin-right: 3rem;
  cursor: pointer;
}

.favouritesContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  margin-right: 3rem;
  cursor: pointer;
}

.scoreIconContainer {
  display: flex;
  justify-content: center;
}

.srijconScoreImg {
  height: 3rem;
  width: 3rem;
  margin-right: 0.8rem;
}

.ecoImpactImg {
  height: 3rem;
  width: 3rem;
  margin-right: 0.8rem;
}

.socialImpactImg {
  height: 3rem;
  width: 3rem;
  margin-right: 0.8rem;
}

.score {
  margin: auto;
  font-weight: 800;
  font-size: 2rem;
  color: var(--color-gray-dark-1);
}

.scoreContainerText {
  margin-top: 0.5rem;
  font-size: 1.4rem;
  color: var(--color-gray-medium-2);
}

.fansAndFavouritesContainerText {
  font-weight: 550;
  margin-top: 0.7rem;
  font-size: 1.4rem;
  color: var(--color-gray-medium-3);
}

@media screen and (max-width: 900px) {
  .UserProfile {
    width: 60rem;
  }

  .userBanner img {
    width: 60rem;
    aspect-ratio: 3;
  }

  .userAvtar img {
    border: 0.2rem solid var(--color-white);
    height: 14.4rem;
    width: 14.4rem;
    margin-top: -8.8rem;
    margin-left: 3rem;
  }

  .userNameText {
    margin-left: 3rem;
    margin-top: 0;
    width: 100%;
  }

  .userNameText h2 {
    font-size: 2.4rem;
  }

  .userNameText p {
    font-size: 1.6rem;
  }

  .userScore {
    width: 60rem;
    margin: 1rem 0;
  }

  .Score1 {
    margin-right: 3.5rem;
  }

  .Score2 {
    margin: 0 1rem;
    justify-content: flex-start;
  }

  .vl {
    margin-left: -0.6rem;
    margin-right: 1.2rem;
  }

  .editBannerPencilDiv {
    margin-top: -3rem;
    margin-left: 48rem;
    margin-bottom: -8.1rem;
  }

  .shareIconContainer {
    height: 4rem;
    width: 4rem;
  }

  .editBannerPencil {
    height: 4rem;
    width: 4rem;
  }

  .fansUserPlusIcon {
    top: 0.5rem;
    left: 54rem;
    height: 4rem;
    width: 4rem;
  }

  .favouritesUserCheckIcon {
    top: 4.5rem;
    left: 54rem;
    height: 4rem;
    width: 4rem;
  }

  .marks {
    justify-content: center;
  }

  .Score1 img {
    height: 3rem;
    width: 3rem;
  }

  .postSection {
    width: 60rem;
  }

  .postHeader {
    margin: 0 0 0.7rem;
  }

  .modalImages {
    width: 43.3%;
    aspect-ratio: 3/2;
    margin: 1rem;
  }

  .scoreContainer {
    margin-top: 2.2rem;
    margin-bottom: 2.2rem;
    justify-content: space-between;
  }

  .srijconScoreContainer {
    margin: 0;
  }

  .ecoImpactContainer {
    margin: 0;
  }

  .socialImpactContainer {
    margin: 0;
  }

  .vlContainer {
    margin: 0;
  }

  .fansContainer {
    margin-right: 0;
  }

  .favouritesContainer {
    margin-right: 0;
  }

  .userNameText p {
    color: var(--color-gray-medium-3);
  }

  .scoreContainerText {
    color: var(--color-gray-medium-3);
  }

  .fansAndFavouritesContainerText {
    color: var(--color-gray-dark-2);
  }
}

@media screen and (max-width: 600px) {
  .main {
    background-color: var(--color-white);
  }

  .UserProfile {
    width: 100%;
    border-radius: 0;
    box-shadow: none;
  }

  .vl {
    display: inline;
    border-left: 0.2rem solid var(--color-gray-light-3);
    height: 10vw;
    margin-top: 4vw;
  }

  .userBanner img {
    width: 100%;
  }

  .editBannerPencilDiv {
    position: relative;
    margin-top: -5vw;
    margin-left: 76vw;
    margin-bottom: -7.7vw;

  }

  .editBannerPencil, .shareIconContainer {
    background-color: var(--color-gray-medium-3);
    height: 8vw;
    width: 8vw;
    padding: 0.5rem;
  }

  .editBannerPencilDiv :hover {
    background-color: var(--color-gray-dark-2);
  }

  .editBannerPencilDiv :active {
    transition: 0.55ms;
    transform: scale(0.96);
  }

  .featherUser {
    height: 5vw;
    width: 5vw;
  }

  .featherUserCheck {
    height: 5vw;
    width: 5vw;
  }

  .featherUser2 {
    height: 5vw;
    width: 5vw;
  }

  .fansUserPlusIcon {
    top: -8vw;
    left: 88vw;
    background-color: var(--color-gray-medium-3);
    height: 8vw;
    width: 8vw;
  }

  .favouritesUserCheckIcon {
    position: relative;
    top: 0;
    left: 88vw;
    box-shadow: 0 0.3rem 0.6rem rgba(var(--color-black-rgb), 0.2);
    border-radius: 100%;
    height: 8vw;
    width: 8vw;
    padding: 0.5rem;
    color: var(--color-white);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .userAvtar {
    margin-top: -25.8vw;
  }

  .userAvtar img {
    border: 0.5vw solid var(--color-white);
    height: 27vw;
    width: 27vw;
    margin-top: 0;
    margin-left: 3.4vw;
  }

  .userNameText {
    margin-left: 3.4vw;
    margin-top: 0;
  }

  .userNameText h2 {
    font-size: 5vw;
  }

  .userNameText p {
    font-size: 3vw;
    color: var(--color-gray-medium-3);
  }

  .userScore {
    width: 100%;
    margin: 3vw auto;
  }

  .post {
    margin-top: 1%;
  }

  .postSection {
    border-radius: 0;
    background-color: var(--color-white);
    width: 100%;
    box-shadow: none;
    margin: 1% auto auto;
  }

  .postHeadings {
    display: block;
  }

  .post1 .images {
    margin-top: 13%;
  }

  .modalImages {
    border-radius: 1rem;
    width: 100%;
    aspect-ratio: 3/2;
    margin: 3.4vw;
  }

  .myContentBar {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0;
    margin-top: 0;
    padding: 0 5vw 0 0;
  }

  .postHeader {
    display: flex;
    justify-content: center;
    margin: 1.5vw 0;
    padding-top: 1vw;
  }

  .vertical1 {
    margin-left: 33%;
    margin-top: -9%;
  }

  .vertical {
    margin-left: 70%;
    margin-top: -20%;
  }

  .content {
    display: block;
    margin: 0 3.4vw;
    justify-content: space-between;
    padding-bottom: 1.5vw;
  }

  .images {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  .userScore {
    justify-content: center;
    align-items: center;
  }

  .Score1 {
    margin: 0 6vw 0 0;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
  }

  .Score2 {
    cursor: pointer;
    margin: 0 3vw 0 0;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
  }

  .srijconscore {
    margin: auto auto auto 1vw;
    font-weight: 800;
    font-size: 3vw;
    color: var(--color-gray-dark-1);
  }

  .fansScore,
  .favouritesScore {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    font-weight: 800;
    font-size: 3vw;
    color: red;
  }

  .Score1 img {
    height: 5vw;
    width: 5vw;
  }

  .jagjot {
    display: flex;
    align-items: center;
  }

  .jagjot2 {
    display: flex;
    align-items: center;
  }

  .srijcontitle {
    position: absolute;
    margin: 10vw auto auto 0;
    width: max-content;
    font-size: 3vw;
    color: var(--color-gray-medium-2);
  }

  .fansTitle,
  .favouritesTitle {
    position: absolute;
    margin: 10vw auto auto;
    width: max-content;
    font-size: 3vw;
    color: var(--color-gray-medium-3);
    font-weight: 550;
  }

  .srijconContainer {
    display: flex;
    margin-left: 3.4vw;
    justify-content: flex-start;
    align-items: center;
  }

  .marks {
    display: flex;
    align-items: center;
  }

  .marks2 {
    margin-left: 2vw;
    display: flex;
    align-items: center;
  }

  .scoreContainer {
    display: flex;
    margin: 5vw 3vw;
  }

  .srijconScoreContainer {
    flex-direction: column;
    align-items: center;
    width: fit-content;
    margin: 0;
    display: none;
  }

  .ecoImpactContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: fit-content;
    margin: 0;
  }

  .socialImpactContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: fit-content;
    margin: 0;
  }

  .vlContainer {
    display: flex;
    flex-direction: column;
    width: fit-content;
    margin: 0;
  }

  .fansContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: fit-content;
    margin: 0;
    cursor: pointer;
  }

  .favouritesContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: fit-content;
    margin: 0;
    cursor: pointer;
  }

  .scoreIconContainer {
    display: flex;
    justify-content: center;
  }

  .srijconScoreImg {
    height: 5vw;
    width: 5vw;
    margin-right: 1vw;
  }

  .ecoImpactImg {
    height: 5vw;
    width: 5vw;
    margin-right: 1vw;
  }

  .socialImpactImg {
    height: 5vw;
    width: 5vw;
    margin-right: 1vw;
  }

  .score {
    margin: auto;
    font-weight: 800;
    font-size: 3.5vw;
    color: var(--color-gray-dark-1);
  }

  .scoreContainerText {
    margin-top: 0.8vw;
    font-size: 2.5vw;
  }

  .fansAndFavouritesContainerText {
    margin-top: 1vw;
    font-size: 2.5vw;
  }

  .emptyDiv {
    height: 5rem;
  }

  .multipleIcon {
    top: 0.5vw;
    right: 0.5vw;
    font-size: 5vw;
  }
}
/* Avatar, Name etc container */
.header {
  display: flex;
  align-items: center;
  padding: 1rem;
  height: 5.5rem;
}

/* Avatar Styles */
.avatar {
  margin-right: 1.5rem;
  cursor: pointer;
}

/* Name and content type container */
.about {
  margin-right: auto;
}

/* Name styles */
.name {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.2;
  color: var(--color-gray-dark-1);
  margin: 0;
  cursor: pointer;
  display: block;
  /* width: 30ch; */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* Content type style */
.contentType {
  font-size: 1.2rem;
  color: var(--color-gray-medium-2);
  margin: 0;
  text-transform: capitalize;
  display: block;
  /* width: 45ch; */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* More Icon style */
.moreIcon {
  color: var(--color-gray-medium-2);
  padding: 0.3rem;
  cursor: pointer;
}

/* More Container contains More Icon and TV Card Dropdown */
.moreContainer {
  position: relative;
}
